export const statuses = [
  {
    name: 'В очереди',
    count: 10
  },
  {
    name: 'Завершено',
    count: 22
  },
  {
    name: 'В работе',
    count: 10
  },
  {
    name: 'Отменено',
    count: 10
  }
]
export const groups = [
  {
    'name': 'IT',
    'children': [{
      'name': 'Karen England',
      'tasks': [{
        'user': 'Karen England',
        'status': 'Завершено',
        'balance': '$2,731.76',
        'company': 'COASH',
        'phone': '+1 (893) 487-3542',
        'address': '801 Rodney Street, Hannasville, Arizona, 4141',
        'about': 'Quis sunt ea magna cupidatat aliqua eiusmod laborum adipisicing sit ea est sit. Incididunt culpa excepteur eiusmod sunt consectetur in exercitation dolore nostrud non non sunt. Labore sit pariatur consequat quis eu quis adipisicing fugiat mollit laboris consectetur dolore. Reprehenderit sit qui veniam aute qui irure nisi laborum excepteur dolor ipsum sunt consequat. Ut minim in fugiat aliquip et tempor quis est tempor ad.',
        'registered': 'Wednesday, March 20, 2019 11:04 AM',
        'latitude': '-62.967909',
        'department': 'IT'
      }]
    }, {
      'name': 'Mara Lewis',
      'tasks': [{
        'user': 'Mara Lewis',
        'status': 'Отменено',
        'balance': '$1,078.55',
        'company': 'SCENTY',
        'phone': '+1 (801) 455-2702',
        'address': '871 Rost Place, Hayes, Illinois, 5044',
        'about': 'Consequat laboris occaecat fugiat consectetur. Adipisicing eu dolor voluptate est fugiat eu id qui incididunt sunt esse est consequat aliqua. Duis et tempor et deserunt laboris magna sint labore ut amet. Esse laboris irure labore id aliquip veniam fugiat amet deserunt eu ullamco officia. Quis quis deserunt officia aliqua qui ut deserunt velit sint qui cillum ullamco.',
        'registered': 'Tuesday, September 11, 2018 9:22 AM',
        'latitude': '33.956828',
        'department': 'IT'
      }, {
        'user': 'Mara Lewis',
        'status': 'В очереди',
        'balance': '$2,543.44',
        'company': 'COMTOURS',
        'phone': '+1 (866) 590-2396',
        'address': '939 Hubbard Street, Hartsville/Hartley, Maryland, 7555',
        'about': 'Laborum consectetur ipsum aute nulla officia Lorem non fugiat sit sit aute deserunt. Mollit sint eiusmod exercitation velit nisi veniam veniam laboris ex. Sint do est nulla cupidatat sit officia aute ea cillum.',
        'registered': 'Friday, April 27, 2018 12:30 AM',
        'latitude': '30.48449',
        'department': 'IT'
      }, {
        'user': 'Mara Lewis',
        'status': 'В работе',
        'balance': '$3,861.76',
        'company': 'JUNIPOOR',
        'phone': '+1 (957) 550-3231',
        'address': '587 Mill Road, Rockbridge, Illinois, 5811',
        'about': 'Dolore enim ut ex est sint exercitation enim fugiat est amet sint. Aute cillum sit aute pariatur est minim cupidatat incididunt occaecat eiusmod do exercitation anim minim. Consequat magna irure id cupidatat sunt officia sint mollit ullamco magna ullamco culpa. Ad pariatur aliqua anim eiusmod do minim mollit occaecat aliquip commodo sunt in. Ad sit id ut officia esse ut magna mollit non sunt reprehenderit nulla.',
        'registered': 'Friday, January 23, 2015 2:10 PM',
        'latitude': '-5.90907',
        'department': 'IT'
      }, {
        'user': 'Mara Lewis',
        'status': 'В очереди',
        'balance': '$3,611.52',
        'company': 'SQUISH',
        'phone': '+1 (982) 492-2287',
        'address': '195 Veterans Avenue, Fulford, Pennsylvania, 230',
        'about': 'Consectetur id mollit voluptate cupidatat magna irure esse. Cillum ad incididunt velit laboris esse ipsum ullamco ea cupidatat consectetur officia. Proident qui laborum sunt aliqua aute sunt aute commodo aliquip laborum est. Est occaecat occaecat cillum irure consequat duis non et labore incididunt cupidatat.',
        'registered': 'Thursday, May 10, 2018 3:26 PM',
        'latitude': '-5.635773',
        'department': 'IT'
      }, {
        'user': 'Mara Lewis',
        'status': 'В очереди',
        'balance': '$1,476.22',
        'company': 'MICRONAUT',
        'phone': '+1 (912) 587-2415',
        'address': '890 Losee Terrace, Lowell, South Dakota, 271',
        'about': 'Labore voluptate labore voluptate eu irure ea et esse cupidatat. Proident quis enim qui occaecat et in in veniam adipisicing id id amet sunt. Ullamco sit excepteur magna eiusmod occaecat ea officia. Sit occaecat eu eu in. Commodo incididunt in irure minim. Officia exercitation eiusmod aliquip minim.',
        'registered': 'Monday, June 18, 2018 1:13 AM',
        'latitude': '-82.52535',
        'department': 'IT'
      }, {
        'user': 'Mara Lewis',
        'status': 'В работе',
        'balance': '$3,209.57',
        'company': 'CABLAM',
        'phone': '+1 (918) 567-3799',
        'address': '760 Noble Street, Balm, Colorado, 6866',
        'about': 'Adipisicing ad amet sint exercitation aliquip mollit sunt sunt sint cillum occaecat eiusmod. Cillum voluptate sint aute occaecat consectetur pariatur officia labore ex non do dolore. Commodo incididunt irure et eiusmod in est et et labore. Excepteur deserunt duis mollit eiusmod proident enim quis laboris proident reprehenderit do consectetur ipsum pariatur. Anim ipsum nisi culpa elit cillum nisi deserunt fugiat nostrud culpa magna Lorem cupidatat. Adipisicing ullamco excepteur ipsum deserunt adipisicing laborum cupidatat anim reprehenderit. Minim non veniam laborum eiusmod proident cillum dolore esse.',
        'registered': 'Tuesday, May 30, 2017 9:40 AM',
        'latitude': '-7.127519',
        'department': 'IT'
      }]
    }, {
      'name': 'Stephanie Stanton',
      'tasks': [{
        'user': 'Stephanie Stanton',
        'status': 'В работе',
        'balance': '$1,628.79',
        'company': 'MEDESIGN',
        'phone': '+1 (811) 457-2713',
        'address': '104 Bay Street, Floriston, Washington, 9283',
        'about': 'Sunt enim laborum cupidatat et Lorem dolor minim sunt mollit tempor. Tempor exercitation mollit do aute ut sint cillum cillum officia ipsum labore anim ipsum. Irure nisi quis consequat amet culpa minim non. Dolor ipsum dolore consectetur enim id aute ad.',
        'registered': 'Tuesday, January 20, 2015 8:38 AM',
        'latitude': '-6.861642',
        'department': 'IT'
      }, {
        'user': 'Stephanie Stanton',
        'status': 'Отменено',
        'balance': '$1,934.27',
        'company': 'PLAYCE',
        'phone': '+1 (970) 447-2018',
        'address': '941 Dorset Street, Brownlee, Montana, 8447',
        'about': 'Eiusmod enim sint voluptate eu non quis minim magna qui et ea labore magna non. Ex eu excepteur occaecat incididunt ea excepteur mollit adipisicing dolor voluptate in occaecat aliquip. Dolore laborum nulla ea Lorem enim excepteur eu in aute incididunt quis consequat occaecat excepteur. Aliqua dolor laborum laborum do. Consequat veniam nisi elit eiusmod ut velit pariatur. Minim consectetur magna exercitation officia esse velit aliquip aliqua magna irure enim minim nisi ea.',
        'registered': 'Thursday, May 15, 2014 10:19 PM',
        'latitude': '-26.779293',
        'department': 'IT'
      }]
    }, {
      'name': 'Thompson Heath',
      'tasks': [{
        'user': 'Thompson Heath',
        'status': 'Завершено',
        'balance': '$2,100.20',
        'company': 'OVERPLEX',
        'phone': '+1 (975) 448-2264',
        'address': '195 Lake Street, Movico, Oregon, 927',
        'about': 'Et elit et quis id ut ut elit officia cupidatat reprehenderit pariatur ea dolor fugiat. Culpa sint pariatur do velit culpa ex occaecat et sit cupidatat eiusmod reprehenderit fugiat aliquip. Est deserunt nisi magna cupidatat et non. Aute ad et esse excepteur officia ipsum commodo enim irure enim cillum dolor. Commodo qui ea dolore adipisicing sint esse fugiat voluptate consectetur aliquip ullamco laboris mollit. Voluptate anim esse excepteur irure ullamco adipisicing anim minim sint nisi tempor culpa dolore.',
        'registered': 'Monday, April 13, 2015 9:43 PM',
        'latitude': '40.864245',
        'department': 'IT'
      }, {
        'user': 'Thompson Heath',
        'status': 'Завершено',
        'balance': '$2,543.18',
        'company': 'COWTOWN',
        'phone': '+1 (944) 562-2459',
        'address': '296 Willmohr Street, Kent, Indiana, 4890',
        'about': 'Officia ad commodo aute est est. Culpa labore et do cillum pariatur occaecat culpa quis id irure. Elit aute irure qui qui culpa excepteur Lorem sit nisi consectetur duis.',
        'registered': 'Sunday, March 1, 2015 5:49 AM',
        'latitude': '-77.445832',
        'department': 'IT'
      }, {
        'user': 'Thompson Heath',
        'status': 'Отменено',
        'balance': '$1,605.56',
        'company': 'DENTREX',
        'phone': '+1 (971) 401-2774',
        'address': '971 Newport Street, Holtville, Virgin Islands, 869',
        'about': 'Occaecat enim consectetur velit deserunt laboris est ex velit proident officia. Officia eiusmod enim eu labore nulla laborum aliqua elit quis ullamco eu veniam exercitation pariatur. Pariatur fugiat exercitation consectetur consectetur irure excepteur velit labore magna ipsum officia occaecat. Nisi dolor aliquip consequat nostrud. Aliqua in voluptate voluptate ut elit id enim deserunt irure anim adipisicing irure.',
        'registered': 'Friday, June 1, 2018 9:53 PM',
        'latitude': '-57.664961',
        'department': 'IT'
      }, {
        'user': 'Thompson Heath',
        'status': 'Завершено',
        'balance': '$3,220.96',
        'company': 'SPEEDBOLT',
        'phone': '+1 (861) 458-2188',
        'address': '907 Pacific Street, Alden, Colorado, 2963',
        'about': 'Irure culpa officia eiusmod Lorem ea do ut quis in nulla adipisicing quis consequat. Duis Lorem aute laboris officia. Proident adipisicing nulla irure cupidatat fugiat do excepteur voluptate ut reprehenderit sint.',
        'registered': 'Tuesday, January 27, 2015 12:28 PM',
        'latitude': '54.643023',
        'department': 'IT'
      }]
    }, {
      'name': 'Barton Torres',
      'tasks': [{
        'user': 'Barton Torres',
        'status': 'Завершено',
        'balance': '$1,676.46',
        'company': 'RUBADUB',
        'phone': '+1 (835) 478-3046',
        'address': '152 Hoyts Lane, Dubois, Hawaii, 6230',
        'about': 'Pariatur proident consequat consequat occaecat dolore elit ullamco non eu. Velit pariatur eiusmod mollit ipsum nulla deserunt minim aliquip anim eu commodo aliquip officia. Excepteur exercitation ullamco anim duis aliquip minim elit excepteur deserunt mollit aliquip pariatur excepteur amet. Do labore id officia sint qui.',
        'registered': 'Saturday, December 1, 2018 1:39 PM',
        'latitude': '-15.215431',
        'department': 'IT'
      }, {
        'user': 'Barton Torres',
        'status': 'Отменено',
        'balance': '$3,160.09',
        'company': 'PARCOE',
        'phone': '+1 (862) 517-2593',
        'address': '838 Ludlam Place, Dowling, New Mexico, 2583',
        'about': 'Dolore aliqua ea adipisicing labore minim aliquip. Aliquip est tempor voluptate ullamco proident cillum. Deserunt eiusmod id labore dolor irure enim consectetur elit excepteur ipsum do anim deserunt. Sint consequat elit non veniam eiusmod deserunt aute cupidatat voluptate irure Lorem elit officia. Commodo commodo pariatur minim irure ullamco nostrud exercitation proident amet deserunt deserunt Lorem cupidatat.',
        'registered': 'Friday, February 17, 2017 11:52 AM',
        'latitude': '58.454163',
        'department': 'IT'
      }, {
        'user': 'Barton Torres',
        'status': 'В работе',
        'balance': '$3,160.93',
        'company': 'SCHOOLIO',
        'phone': '+1 (837) 449-2827',
        'address': '302 Richards Street, Sunriver, Vermont, 1263',
        'about': 'Labore proident officia do sunt aute qui dolore. Eu voluptate laborum pariatur consequat adipisicing consectetur dolore ullamco est velit aute. Aute nisi aliqua est reprehenderit eu ad occaecat dolor anim ut laboris ut officia. Dolore dolor eiusmod excepteur reprehenderit. Cillum ullamco cupidatat amet veniam voluptate minim pariatur cupidatat incididunt ad ea duis.',
        'registered': 'Wednesday, August 17, 2016 5:51 PM',
        'latitude': '-17.353372',
        'department': 'IT'
      }, {
        'user': 'Barton Torres',
        'status': 'В очереди',
        'balance': '$1,553.49',
        'company': 'FANFARE',
        'phone': '+1 (963) 582-2580',
        'address': '395 Veronica Place, Bartonsville, New Hampshire, 5096',
        'about': 'Adipisicing fugiat ea anim sunt ad esse exercitation ipsum velit Lorem Lorem consequat nulla cillum. Anim nulla aliqua sint culpa non voluptate est. Est aliquip ea non cillum Lorem do ad do dolore. Qui amet et sunt elit in id esse minim veniam magna. Fugiat Lorem consequat nostrud ipsum eu exercitation nostrud eu et ipsum do dolor ex anim.',
        'registered': 'Thursday, June 27, 2019 3:40 PM',
        'latitude': '31.334761',
        'department': 'IT'
      }]
    }, {
      'name': 'Vilma Caldwell',
      'tasks': [{
        'user': 'Vilma Caldwell',
        'status': 'Отменено',
        'balance': '$2,405.71',
        'company': 'MUSANPOLY',
        'phone': '+1 (898) 423-3994',
        'address': '827 Nassau Avenue, Hiko, Maryland, 7236',
        'about': 'Anim adipisicing aute sit aute proident id. Reprehenderit consequat est consequat et culpa amet do amet. Ex minim ipsum ullamco cupidatat id est tempor et nulla. Proident aliqua excepteur id reprehenderit culpa. Nostrud ex culpa enim elit voluptate est voluptate incididunt irure. Aute nisi cupidatat laborum irure ex. Sit dolore aute quis deserunt qui fugiat consequat excepteur cupidatat sint mollit.',
        'registered': 'Friday, April 6, 2018 12:11 PM',
        'latitude': '-11.371784',
        'department': 'IT'
      }, {
        'user': 'Vilma Caldwell',
        'status': 'В работе',
        'balance': '$1,751.97',
        'company': 'ACCIDENCY',
        'phone': '+1 (820) 497-3704',
        'address': '354 Greene Avenue, Martinsville, Federated States Of Micronesia, 4532',
        'about': 'Enim eu est irure ut sit aute mollit pariatur quis tempor minim veniam proident. Esse do sint sunt et. Cupidatat qui eu velit Lorem aliqua nulla culpa veniam. Anim nostrud eu id esse. Proident do ullamco ut duis cillum aliqua cillum.',
        'registered': 'Tuesday, October 29, 2019 11:23 AM',
        'latitude': '-29.369235',
        'department': 'IT'
      }, {
        'user': 'Vilma Caldwell',
        'status': 'В работе',
        'balance': '$2,026.73',
        'company': 'COMCUBINE',
        'phone': '+1 (876) 485-2042',
        'address': '289 Sackett Street, Drummond, West Virginia, 6743',
        'about': 'Exercitation mollit minim amet id sunt ut Lorem Lorem excepteur duis cillum mollit. Ea sunt proident ullamco sit occaecat mollit anim. Lorem voluptate Lorem aute enim tempor quis consectetur id anim non anim qui velit. Incididunt sit sit id ipsum excepteur. Nulla proident occaecat cillum incididunt veniam.',
        'registered': 'Saturday, September 20, 2014 9:59 PM',
        'latitude': '-54.563317',
        'department': 'IT'
      }, {
        'user': 'Vilma Caldwell',
        'status': 'В очереди',
        'balance': '$2,225.32',
        'company': 'ZAPHIRE',
        'phone': '+1 (903) 551-3417',
        'address': '924 Locust Street, Rehrersburg, Massachusetts, 3244',
        'about': 'Lorem do ad Lorem cupidatat voluptate sint aute. Ex duis excepteur nostrud nulla elit. Ea aute nulla est Lorem et eu.',
        'registered': 'Sunday, June 19, 2016 1:14 PM',
        'latitude': '-52.907995',
        'department': 'IT'
      }, {
        'user': 'Vilma Caldwell',
        'status': 'Отменено',
        'balance': '$3,677.38',
        'company': 'TOYLETRY',
        'phone': '+1 (988) 450-2942',
        'address': '578 Rock Street, Greenbackville, Minnesota, 2371',
        'about': 'Laborum cupidatat magna amet nulla eu consectetur pariatur dolor laboris ex occaecat. Id quis cillum nulla pariatur tempor laborum dolor ipsum amet aliqua ad voluptate. Consectetur qui eu veniam sit. Pariatur exercitation aute aliquip fugiat eu et cupidatat dolore sunt ut aliquip veniam velit culpa.',
        'registered': 'Sunday, August 23, 2015 7:06 AM',
        'latitude': '58.694045',
        'department': 'IT'
      }]
    }, {
      'name': 'Esmeralda Underwood',
      'tasks': [{
        'user': 'Esmeralda Underwood',
        'status': 'Завершено',
        'balance': '$2,597.04',
        'company': 'VANTAGE',
        'phone': '+1 (979) 425-2586',
        'address': '416 Claver Place, Sutton, Pennsylvania, 6761',
        'about': 'Enim ullamco deserunt id in nulla dolore voluptate nostrud sint commodo cillum. Consectetur ut tempor ad veniam minim. Excepteur ad occaecat voluptate tempor et. Cupidatat pariatur cupidatat dolore minim aute aliqua culpa qui cupidatat proident tempor excepteur quis eu.',
        'registered': 'Saturday, June 9, 2018 1:43 AM',
        'latitude': '-15.055972',
        'department': 'IT'
      }, {
        'user': 'Esmeralda Underwood',
        'status': 'Отменено',
        'balance': '$2,292.64',
        'company': 'APEXIA',
        'phone': '+1 (917) 423-3528',
        'address': '948 Dean Street, Lookingglass, Palau, 130',
        'about': 'Voluptate veniam nostrud adipisicing amet ex eu sint do cupidatat voluptate ad in proident. Veniam cillum officia aliquip Lorem aute magna velit elit deserunt occaecat incididunt. Eu officia deserunt sit occaecat tempor cillum. Et qui cupidatat aliquip sit. Anim sit laboris officia sint et est pariatur est cillum do incididunt magna. Culpa amet ipsum aute in exercitation commodo.',
        'registered': 'Wednesday, November 8, 2017 9:57 PM',
        'latitude': '-37.137828',
        'department': 'IT'
      }, {
        'user': 'Esmeralda Underwood',
        'status': 'В очереди',
        'balance': '$3,236.39',
        'company': 'IMMUNICS',
        'phone': '+1 (857) 429-3050',
        'address': '204 Metropolitan Avenue, Noxen, Utah, 9162',
        'about': 'Nulla veniam nostrud tempor ut qui proident do in consequat elit non cillum irure cillum. Labore laboris quis in nulla pariatur cupidatat commodo ex esse officia pariatur. Anim occaecat eiusmod adipisicing consequat. Cupidatat ad deserunt et eu consequat Lorem magna aliqua.',
        'registered': 'Monday, July 27, 2015 7:12 PM',
        'latitude': '10.411223',
        'department': 'IT'
      }, {
        'user': 'Esmeralda Underwood',
        'status': 'Завершено',
        'balance': '$3,584.46',
        'company': 'VIAGRAND',
        'phone': '+1 (986) 513-3466',
        'address': '631 Carlton Avenue, Freelandville, New York, 1527',
        'about': 'Ea aute et in ipsum mollit. Fugiat id consectetur laboris do. Esse nisi in nostrud esse dolore aute ut duis quis pariatur laboris occaecat deserunt ullamco. Reprehenderit mollit enim sint eu mollit. Quis excepteur dolor duis ad eu. Aliqua est est esse magna.',
        'registered': 'Tuesday, August 21, 2018 7:49 PM',
        'latitude': '-17.509669',
        'department': 'IT'
      }, {
        'user': 'Esmeralda Underwood',
        'status': 'Завершено',
        'balance': '$1,319.12',
        'company': 'GEOFORM',
        'phone': '+1 (927) 557-3727',
        'address': '446 Little Street, Newry, West Virginia, 8257',
        'about': 'Do amet deserunt pariatur eiusmod esse ex dolore aliquip irure aliqua veniam ex tempor. Qui esse cupidatat laboris sunt duis amet et anim officia sunt excepteur. Laborum nisi velit velit aliquip in ad ipsum adipisicing ex aliqua cillum. Proident tempor nisi eiusmod non mollit consectetur do esse ex in velit. Velit cupidatat id dolore est tempor irure amet incididunt dolor aute fugiat incididunt dolore deserunt. Commodo duis ad quis laborum dolore aliquip cupidatat labore commodo. Magna aliquip in ut ullamco consectetur.',
        'registered': 'Thursday, June 1, 2017 1:16 PM',
        'latitude': '11.61662',
        'department': 'IT'
      }]
    }, {
      'name': 'Frost Craig',
      'tasks': [{
        'user': 'Frost Craig',
        'status': 'Завершено',
        'balance': '$3,594.83',
        'company': 'MATRIXITY',
        'phone': '+1 (896) 479-3087',
        'address': '960 Oceanic Avenue, Vernon, Guam, 4762',
        'about': 'Nostrud dolor irure anim incididunt culpa laborum Lorem. Dolore qui ea incididunt consectetur aliqua laborum. Sunt mollit veniam amet sunt quis ipsum anim duis elit culpa nulla proident sit. Culpa nisi nisi sunt pariatur irure. Excepteur aliqua ullamco sunt nulla voluptate eiusmod ad aliqua deserunt. Culpa sunt ipsum ullamco occaecat duis adipisicing aute aute enim aute. Incididunt amet consectetur non velit do consequat esse minim eu excepteur enim minim nostrud.',
        'registered': 'Tuesday, September 11, 2018 1:51 PM',
        'latitude': '41.127078',
        'department': 'IT'
      }, {
        'user': 'Frost Craig',
        'status': 'В работе',
        'balance': '$3,294.24',
        'company': 'GRONK',
        'phone': '+1 (840) 467-3866',
        'address': '770 Truxton Street, Courtland, Maine, 9756',
        'about': 'Voluptate nostrud incididunt occaecat eu commodo adipisicing exercitation tempor. Incididunt reprehenderit magna minim consequat nostrud non. Occaecat veniam elit officia non sunt aliquip irure laboris.',
        'registered': 'Monday, June 17, 2019 11:14 PM',
        'latitude': '-87.91548',
        'department': 'IT'
      }, {
        'user': 'Frost Craig',
        'status': 'Завершено',
        'balance': '$3,591.64',
        'company': 'ZAYA',
        'phone': '+1 (858) 461-2026',
        'address': '458 Woodruff Avenue, Roderfield, Florida, 4734',
        'about': 'Nulla incididunt laboris quis quis mollit irure elit ipsum est reprehenderit. Nostrud aliqua amet ea non adipisicing duis ipsum labore reprehenderit labore enim. Consectetur incididunt voluptate sint duis officia id laboris occaecat consectetur in veniam.',
        'registered': 'Wednesday, June 28, 2017 5:49 AM',
        'latitude': '48.906749',
        'department': 'IT'
      }, {
        'user': 'Frost Craig',
        'status': 'В работе',
        'balance': '$1,892.49',
        'company': 'KRAGGLE',
        'phone': '+1 (897) 474-3159',
        'address': '957 Colby Court, Greenbush, Texas, 9482',
        'about': 'Non eiusmod aliquip consequat non adipisicing commodo esse sint cillum consectetur esse cupidatat. Non ullamco aliqua id culpa Lorem eiusmod mollit. Do dolor esse est nulla Lorem enim duis cillum. Nulla tempor incididunt dolore eiusmod id consectetur incididunt magna est dolore laborum labore. Irure Lorem commodo consequat est. Laborum occaecat excepteur ex commodo aliquip dolore duis reprehenderit sit deserunt.',
        'registered': 'Thursday, August 7, 2014 10:56 AM',
        'latitude': '46.610611',
        'department': 'IT'
      }]
    }]
  }, {
    'name': 'Маркетинг',
    'children': [{
      'name': 'Vickie Burke',
      'tasks': [{
        'user': 'Vickie Burke',
        'status': 'В работе',
        'balance': '$1,151.56',
        'company': 'COMTRAK',
        'phone': '+1 (974) 545-2126',
        'address': '902 Arlington Place, Bordelonville, New York, 4594',
        'about': 'Consequat id commodo id sunt irure aliqua officia voluptate ullamco eiusmod ut. Amet dolor incididunt elit minim cupidatat nostrud culpa pariatur officia occaecat. Cupidatat tempor labore nulla anim.',
        'registered': 'Wednesday, July 29, 2015 10:21 AM',
        'latitude': '25.05401',
        'department': 'Маркетинг'
      }, {
        'user': 'Vickie Burke',
        'status': 'Отменено',
        'balance': '$1,112.56',
        'company': 'SNIPS',
        'phone': '+1 (844) 473-2650',
        'address': '459 Roosevelt Place, Northridge, South Dakota, 2549',
        'about': 'Mollit magna cillum ipsum qui enim laborum aute non eu et qui enim. Veniam incididunt qui aliqua consectetur aute eiusmod incididunt esse voluptate do sint aute exercitation. Sunt cupidatat est ullamco qui incididunt.',
        'registered': 'Sunday, February 23, 2014 8:16 AM',
        'latitude': '1.129789',
        'department': 'Маркетинг'
      }, {
        'user': 'Vickie Burke',
        'status': 'В работе',
        'balance': '$1,258.84',
        'company': 'VIRXO',
        'phone': '+1 (819) 402-3763',
        'address': '280 Scholes Street, Sparkill, Ohio, 9846',
        'about': 'In est aliquip eu dolor cupidatat anim esse consequat. Lorem tempor aliqua minim adipisicing fugiat. Ad in anim reprehenderit magna fugiat.',
        'registered': 'Friday, March 30, 2018 7:56 PM',
        'latitude': '0.498599',
        'department': 'Маркетинг'
      }, {
        'user': 'Vickie Burke',
        'status': 'Завершено',
        'balance': '$2,057.10',
        'company': 'POLARIA',
        'phone': '+1 (926) 420-3831',
        'address': '737 Woodbine Street, Roulette, South Carolina, 2870',
        'about': 'Tempor pariatur eiusmod sit qui adipisicing eiusmod sunt enim aliquip sint. Minim do sunt sunt pariatur officia sit ea fugiat incididunt. Labore est ex ut eu aliquip dolor eu ad culpa nostrud. Consequat sint aute eu ipsum proident aute Lorem esse aute.',
        'registered': 'Friday, March 11, 2016 10:46 AM',
        'latitude': '-61.23999',
        'department': 'Маркетинг'
      }, {
        'user': 'Vickie Burke',
        'status': 'Завершено',
        'balance': '$2,301.45',
        'company': 'KEEG',
        'phone': '+1 (828) 506-3031',
        'address': '409 Ocean Avenue, Bancroft, Idaho, 9000',
        'about': 'Amet reprehenderit quis nostrud dolor sunt. Cupidatat esse officia do ullamco. Pariatur nostrud in id aute ea cillum sint in ipsum consectetur minim aliquip elit ut. Non esse occaecat cupidatat id voluptate tempor voluptate Lorem fugiat eu est elit fugiat. Aute nostrud pariatur magna aliquip voluptate.',
        'registered': 'Thursday, April 24, 2014 2:03 PM',
        'latitude': '22.225605',
        'department': 'Маркетинг'
      }]
    }, {
      'name': 'Tillman Stephenson',
      'tasks': [{
        'user': 'Tillman Stephenson',
        'status': 'Отменено',
        'balance': '$2,054.09',
        'company': 'NURALI',
        'phone': '+1 (924) 567-3709',
        'address': '518 Preston Court, Dawn, Idaho, 8949',
        'about': 'Est occaecat fugiat deserunt officia eu. Officia occaecat sunt tempor aliquip enim. Amet velit nisi deserunt ex. Nisi proident adipisicing eu duis adipisicing cillum et anim ullamco dolore adipisicing. Sit eu dolore laboris aliquip officia ullamco ex non reprehenderit. Dolore officia pariatur id pariatur esse proident enim. Nulla occaecat est commodo eu ullamco Lorem qui.',
        'registered': 'Thursday, January 14, 2016 12:10 PM',
        'latitude': '-86.063388',
        'department': 'Маркетинг'
      }, {
        'user': 'Tillman Stephenson',
        'status': 'В работе',
        'balance': '$2,381.79',
        'company': 'NETPLODE',
        'phone': '+1 (900) 595-3675',
        'address': '371 Johnson Street, Woodlands, Louisiana, 1509',
        'about': 'Minim adipisicing irure duis ex velit nostrud. Qui laboris pariatur quis elit proident et ut fugiat voluptate ex ad. Id ullamco laboris cupidatat nulla ex sint cupidatat commodo consequat incididunt anim in culpa nulla. Ut adipisicing proident occaecat esse in fugiat laborum ea pariatur sint. Excepteur irure labore id ipsum fugiat sit incididunt cupidatat Lorem. Nostrud eiusmod veniam fugiat excepteur sint proident sint veniam incididunt ut pariatur.',
        'registered': 'Sunday, February 9, 2014 3:54 AM',
        'latitude': '66.840828',
        'department': 'Маркетинг'
      }]
    }, {
      'name': 'Chasity Mason',
      'tasks': [{
        'user': 'Chasity Mason',
        'status': 'В работе',
        'balance': '$2,472.29',
        'company': 'TELEQUIET',
        'phone': '+1 (917) 462-2065',
        'address': '599 Cambridge Place, Chical, Georgia, 2446',
        'about': 'Mollit dolor ipsum voluptate proident eiusmod sunt nostrud magna mollit culpa irure amet ullamco. Dolore nostrud laborum mollit sunt do cupidatat commodo. Mollit nulla dolor ut ipsum aute commodo esse Lorem elit magna proident veniam. Id in ex ea veniam Lorem Lorem quis do quis voluptate exercitation aliquip dolore id.',
        'registered': 'Tuesday, October 29, 2019 3:44 PM',
        'latitude': '20.971382',
        'department': 'Маркетинг'
      }, {
        'user': 'Chasity Mason',
        'status': 'В работе',
        'balance': '$3,519.84',
        'company': 'MANUFACT',
        'phone': '+1 (809) 478-2706',
        'address': '490 Willoughby Avenue, Moquino, Kansas, 9206',
        'about': 'Excepteur consectetur ea incididunt velit nulla eiusmod. Excepteur exercitation dolore nostrud nulla sunt eiusmod esse anim sit. Velit irure aliqua irure mollit ex aute labore irure fugiat. Duis laboris esse magna est ut amet. Sunt sint culpa dolore ullamco eiusmod est.',
        'registered': 'Friday, August 7, 2015 5:46 PM',
        'latitude': '-78.036613',
        'department': 'Маркетинг'
      }, {
        'user': 'Chasity Mason',
        'status': 'В очереди',
        'balance': '$3,281.10',
        'company': 'PRINTSPAN',
        'phone': '+1 (864) 556-2682',
        'address': '240 Fleet Place, Moscow, Wyoming, 295',
        'about': 'Commodo qui magna esse sit ad cupidatat minim. Aliquip anim ut cupidatat enim nostrud deserunt est ipsum ex. Mollit officia ipsum culpa ipsum veniam sit. Ipsum aute adipisicing magna labore officia tempor ea consectetur ut eu. Sunt id occaecat nulla officia adipisicing. Sit magna enim in eu et qui ex magna dolore tempor.',
        'registered': 'Wednesday, May 20, 2015 7:36 AM',
        'latitude': '47.641514',
        'department': 'Маркетинг'
      }]
    }, {
      'name': 'Sandra Floyd',
      'tasks': [{
        'user': 'Sandra Floyd',
        'status': 'Завершено',
        'balance': '$3,293.14',
        'company': 'SPRINGBEE',
        'phone': '+1 (970) 560-2575',
        'address': '515 Eaton Court, Elliott, Vermont, 6993',
        'about': 'Fugiat quis labore dolor ut cillum mollit. Velit dolor culpa nostrud deserunt ut ut incididunt ut. Esse voluptate ut ea occaecat. Culpa dolore aliqua occaecat qui dolor.',
        'registered': 'Wednesday, November 12, 2014 6:46 AM',
        'latitude': '39.958969',
        'department': 'Маркетинг'
      }, {
        'user': 'Sandra Floyd',
        'status': 'Отменено',
        'balance': '$3,361.29',
        'company': 'QUANTASIS',
        'phone': '+1 (874) 553-2559',
        'address': '189 Randolph Street, Austinburg, North Dakota, 4810',
        'about': 'Eu quis Lorem ullamco duis nisi deserunt veniam aute est exercitation dolore veniam. Labore excepteur incididunt velit cillum duis ex excepteur culpa mollit amet veniam. Reprehenderit amet magna mollit pariatur ut ad amet ea. Veniam minim ut do ad fugiat in laborum dolor ex cupidatat.',
        'registered': 'Wednesday, March 23, 2016 10:44 PM',
        'latitude': '0.983778',
        'department': 'Маркетинг'
      }]
    }, {
      'name': 'Delia Bradshaw',
      'tasks': [{
        'user': 'Delia Bradshaw',
        'status': 'Отменено',
        'balance': '$2,761.38',
        'company': 'HINWAY',
        'phone': '+1 (835) 476-3127',
        'address': '860 Oak Street, Hiwasse, South Carolina, 7228',
        'about': 'Officia anim occaecat ex nostrud magna cillum nulla fugiat fugiat consequat nulla eu pariatur. Do laborum ex ad nostrud cillum incididunt nisi veniam pariatur culpa. Veniam culpa sunt adipisicing id duis consectetur sit.',
        'registered': 'Tuesday, February 17, 2015 10:44 PM',
        'latitude': '27.412977',
        'department': 'Маркетинг'
      }, {
        'user': 'Delia Bradshaw',
        'status': 'В работе',
        'balance': '$3,297.23',
        'company': 'ZILLACON',
        'phone': '+1 (899) 459-3888',
        'address': '880 Douglass Street, Bath, Kentucky, 1723',
        'about': 'Nulla magna cupidatat exercitation ipsum. Culpa officia commodo exercitation nostrud. Esse anim exercitation consectetur duis ut magna nostrud sit ut anim velit. Irure do esse laboris sit in ex. Excepteur fugiat nulla veniam velit mollit reprehenderit sunt qui culpa non officia. Sit duis pariatur in nostrud minim mollit minim in mollit exercitation tempor qui.',
        'registered': 'Saturday, October 15, 2016 9:01 AM',
        'latitude': '-33.951784',
        'department': 'Маркетинг'
      }]
    }, {
      'name': 'Lesley Beard',
      'tasks': [{
        'user': 'Lesley Beard',
        'status': 'В очереди',
        'balance': '$3,525.13',
        'company': 'COMVEY',
        'phone': '+1 (924) 543-2120',
        'address': '876 Leonora Court, Stewart, Florida, 758',
        'about': 'Aliquip exercitation cupidatat dolor est elit. Reprehenderit sunt ad esse incididunt consequat et tempor. Irure culpa consequat ipsum cupidatat officia irure enim mollit laborum eiusmod. Et elit laboris laboris eu id. Irure tempor dolor velit ullamco ea voluptate fugiat consectetur proident eu excepteur.',
        'registered': 'Friday, January 27, 2017 3:57 PM',
        'latitude': '62.144234',
        'department': 'Маркетинг'
      }]
    }, {
      'name': 'Pamela Knapp',
      'tasks': [{
        'user': 'Pamela Knapp',
        'status': 'В работе',
        'balance': '$3,983.83',
        'company': 'PORTICA',
        'phone': '+1 (900) 502-3978',
        'address': '389 Newton Street, Belfair, Nevada, 4086',
        'about': 'Nulla excepteur excepteur anim magna ex Lorem amet in aliquip commodo deserunt id ipsum enim. Amet mollit sint proident est duis aute. Do esse exercitation culpa culpa cillum aute labore deserunt mollit exercitation pariatur non cillum.',
        'registered': 'Wednesday, April 23, 2014 4:40 PM',
        'latitude': '76.014662',
        'department': 'Маркетинг'
      }, {
        'user': 'Pamela Knapp',
        'status': 'Отменено',
        'balance': '$2,276.75',
        'company': 'PEARLESSA',
        'phone': '+1 (847) 430-3581',
        'address': '830 Bushwick Place, Monument, North Dakota, 9173',
        'about': 'Consequat in aliquip aute officia ad reprehenderit est veniam aliquip consequat ex. Adipisicing nostrud nostrud adipisicing officia laborum magna cillum non est qui exercitation. Occaecat consequat ullamco occaecat id ut irure nostrud ut consectetur. Irure velit ipsum ex enim officia. Laborum commodo reprehenderit officia mollit veniam do labore laborum elit et esse nisi. Lorem laboris commodo reprehenderit ipsum deserunt Lorem ea aliquip eu aute. Laborum eiusmod commodo adipisicing non fugiat quis reprehenderit mollit voluptate velit Lorem dolore ipsum enim.',
        'registered': 'Friday, February 19, 2016 5:45 AM',
        'latitude': '-10.869974',
        'department': 'Маркетинг'
      }, {
        'user': 'Pamela Knapp',
        'status': 'В работе',
        'balance': '$3,490.69',
        'company': 'ORBIXTAR',
        'phone': '+1 (833) 520-3526',
        'address': '800 Kensington Walk, Breinigsville, Alaska, 2777',
        'about': 'Nisi eu aliqua tempor exercitation. Aliqua Lorem magna velit aliqua culpa aute cillum voluptate mollit sunt elit veniam fugiat dolor. Proident aliquip ullamco velit nostrud occaecat nostrud proident pariatur culpa eu pariatur quis adipisicing ea. Lorem id adipisicing veniam nulla. Officia sunt minim velit officia veniam culpa sint eiusmod tempor deserunt sint. Laborum nulla eiusmod ad consectetur do est irure labore pariatur magna in.',
        'registered': 'Sunday, October 18, 2015 9:49 PM',
        'latitude': '-22.362106',
        'department': 'Маркетинг'
      }]
    }]
  }, {
    'name': 'Аналитика',
    'children': [{ 'name': 'Kemp Bush', 'tasks': [] }, {
      'name': 'Berry Bowers',
      'tasks': [{
        'user': 'Berry Bowers',
        'status': 'В работе',
        'balance': '$2,948.47',
        'company': 'CONFERIA',
        'phone': '+1 (852) 507-3291',
        'address': '648 Frost Street, Malo, Oklahoma, 3764',
        'about': 'Ea sunt in amet ex elit nulla nulla magna. Proident nostrud veniam non ad. Officia Lorem officia non anim ullamco tempor velit in cupidatat in.',
        'registered': 'Wednesday, March 27, 2019 9:43 PM',
        'latitude': '65.996371',
        'department': 'Аналитика'
      }, {
        'user': 'Berry Bowers',
        'status': 'В работе',
        'balance': '$1,502.86',
        'company': 'QNEKT',
        'phone': '+1 (922) 593-2797',
        'address': '922 Bassett Avenue, Hobucken, Northern Mariana Islands, 5989',
        'about': 'Amet ad magna esse sit ex ad aliquip ipsum aute. Lorem anim aliquip est duis velit velit ipsum ex nisi veniam laborum mollit. Aute officia tempor irure minim. Irure duis pariatur adipisicing anim nulla amet aliquip elit sit tempor irure.',
        'registered': 'Sunday, June 18, 2017 12:47 PM',
        'latitude': '45.006626',
        'department': 'Аналитика'
      }]
    }, {
      'name': 'Marla Manning',
      'tasks': [{
        'user': 'Marla Manning',
        'status': 'Завершено',
        'balance': '$3,966.53',
        'company': 'BLANET',
        'phone': '+1 (920) 453-3754',
        'address': '690 Buffalo Avenue, Gwynn, Wisconsin, 3282',
        'about': 'Anim minim non eu sint labore commodo enim eiusmod irure nostrud minim nulla irure labore. Dolore anim irure ex laboris incididunt dolore. Anim commodo do consequat duis quis magna id ad sunt cillum deserunt.',
        'registered': 'Monday, June 19, 2017 9:54 PM',
        'latitude': '-61.631967',
        'department': 'Аналитика'
      }]
    }, {
      'name': 'Estella Mckay',
      'tasks': [{
        'user': 'Estella Mckay',
        'status': 'В работе',
        'balance': '$2,282.48',
        'company': 'ELPRO',
        'phone': '+1 (907) 403-3522',
        'address': '974 Gold Street, Itmann, Puerto Rico, 3709',
        'about': 'Irure consequat laborum quis enim ex sit proident nisi duis cupidatat aliquip ad irure excepteur. Duis ipsum est proident esse exercitation. Occaecat Lorem cupidatat commodo ad sint sint amet nisi fugiat duis. Occaecat dolore commodo aliquip irure anim. Fugiat velit et labore ad sit incididunt cillum fugiat est nulla in ad dolore.',
        'registered': 'Tuesday, June 3, 2014 12:18 PM',
        'latitude': '41.472851',
        'department': 'Аналитика'
      }, {
        'user': 'Estella Mckay',
        'status': 'Отменено',
        'balance': '$2,538.07',
        'company': 'PROGENEX',
        'phone': '+1 (827) 486-3847',
        'address': '513 Pine Street, Westerville, Michigan, 1243',
        'about': 'Quis enim commodo elit proident anim elit sunt sit. Elit culpa Lorem ut id cillum reprehenderit. Labore irure sit cupidatat non.',
        'registered': 'Sunday, January 5, 2020 8:16 PM',
        'latitude': '78.179102',
        'department': 'Аналитика'
      }, {
        'user': 'Estella Mckay',
        'status': 'В работе',
        'balance': '$3,849.40',
        'company': 'GEOSTELE',
        'phone': '+1 (995) 572-2471',
        'address': '339 Bush Street, Carrsville, Missouri, 9482',
        'about': 'Nostrud mollit nulla minim ad culpa ad elit esse dolore est aute aute. Lorem laboris cillum pariatur cillum magna adipisicing. Sunt laborum reprehenderit aliqua cillum.',
        'registered': 'Tuesday, March 31, 2015 5:09 PM',
        'latitude': '-69.23743',
        'department': 'Аналитика'
      }]
    }, {
      'name': 'Rowena Bradford',
      'tasks': [{
        'user': 'Rowena Bradford',
        'status': 'В работе',
        'balance': '$2,885.16',
        'company': 'TELPOD',
        'phone': '+1 (892) 400-3115',
        'address': '867 Lott Place, Barronett, Wyoming, 2790',
        'about': 'Esse non tempor do aute. Consectetur dolor est ad culpa Lorem fugiat commodo do qui. Nisi duis adipisicing anim culpa adipisicing ea proident tempor ut esse veniam. Nostrud pariatur exercitation nulla exercitation do laborum aliqua. Occaecat aliqua qui ex eiusmod esse in labore esse pariatur anim nulla proident ipsum. Velit qui exercitation nulla ea Lorem elit culpa elit fugiat.',
        'registered': 'Saturday, October 5, 2019 10:31 AM',
        'latitude': '-3.192261',
        'department': 'Аналитика'
      }, {
        'user': 'Rowena Bradford',
        'status': 'Отменено',
        'balance': '$3,403.83',
        'company': 'BESTO',
        'phone': '+1 (996) 581-3501',
        'address': '573 Bradford Street, Zortman, Nebraska, 7259',
        'about': 'Sunt aute commodo consectetur non est laboris elit voluptate enim. Adipisicing nostrud incididunt esse sunt. Velit mollit fugiat tempor aliquip magna ullamco pariatur laboris dolore non et adipisicing in id. Aliqua mollit magna nisi irure in eu. Pariatur officia amet elit officia ut occaecat laborum occaecat sint sit cupidatat quis.',
        'registered': 'Wednesday, September 9, 2015 8:09 PM',
        'latitude': '0.51085',
        'department': 'Аналитика'
      }]
    }, {
      'name': 'Barbara Figueroa',
      'tasks': [{
        'user': 'Barbara Figueroa',
        'status': 'В работе',
        'balance': '$1,062.13',
        'company': 'TELLIFLY',
        'phone': '+1 (949) 501-2309',
        'address': '214 Ditmars Street, Cowiche, Texas, 8856',
        'about': 'In ad proident velit commodo nostrud nisi anim ullamco aute elit ullamco consequat adipisicing labore. Nisi minim dolore cupidatat veniam excepteur irure proident excepteur commodo commodo. Esse et dolor fugiat dolore aliquip Lorem deserunt nisi nulla ea.',
        'registered': 'Friday, February 12, 2016 2:06 AM',
        'latitude': '21.116737',
        'department': 'Аналитика'
      }, {
        'user': 'Barbara Figueroa',
        'status': 'Отменено',
        'balance': '$3,172.02',
        'company': 'PARAGONIA',
        'phone': '+1 (930) 579-3783',
        'address': '732 Fuller Place, Clarksburg, Louisiana, 4815',
        'about': 'Cillum Lorem eu consequat amet dolor. Sint ex ullamco labore enim adipisicing et id aute amet aute deserunt sint minim consectetur. Ullamco sint tempor tempor proident do. Qui aliquip enim ipsum esse dolor culpa tempor.',
        'registered': 'Tuesday, January 28, 2014 6:32 PM',
        'latitude': '0.923379',
        'department': 'Аналитика'
      }, {
        'user': 'Barbara Figueroa',
        'status': 'Завершено',
        'balance': '$3,837.57',
        'company': 'AUTOMON',
        'phone': '+1 (833) 571-2717',
        'address': '179 Krier Place, Muir, Ohio, 8713',
        'about': 'Excepteur sunt amet anim fugiat irure amet aliqua irure labore id commodo do est exercitation. Fugiat eu ut amet ut pariatur ad Lorem sit exercitation laborum proident eiusmod. Id est nisi magna occaecat sit non Lorem velit qui et veniam nostrud nisi. Mollit elit ex ea incididunt ut ea sit commodo ea est veniam culpa proident. Do quis ea sint qui ex. Ea voluptate reprehenderit ipsum excepteur proident velit laborum minim sint sint nulla. Ad reprehenderit eu proident cillum do voluptate.',
        'registered': 'Monday, September 22, 2014 4:13 AM',
        'latitude': '-4.921739',
        'department': 'Аналитика'
      }]
    }]
  }, {
    'name': 'Бухгалтерия',
    'children': [{ 'name': 'Brown Harvey', 'tasks': [] }, {
      'name': 'Debbie Ayala',
      'tasks': [{
        'user': 'Debbie Ayala',
        'status': 'Завершено',
        'balance': '$1,417.86',
        'company': 'URBANSHEE',
        'phone': '+1 (887) 510-3251',
        'address': '769 Clifton Place, Kirk, Federated States Of Micronesia, 519',
        'about': 'Magna ea enim minim dolor sit velit qui labore reprehenderit quis. Sit fugiat commodo ipsum enim incididunt aute officia nostrud labore ipsum laborum officia incididunt mollit. Nulla laboris ut aute excepteur ut reprehenderit. Exercitation voluptate ipsum excepteur cillum. Duis nulla et in voluptate. Fugiat do qui do cillum labore sit quis proident.',
        'registered': 'Thursday, February 16, 2017 5:28 AM',
        'latitude': '40.771227',
        'department': 'Бухгалтерия'
      }, {
        'user': 'Debbie Ayala',
        'status': 'Завершено',
        'balance': '$2,356.96',
        'company': 'ECOLIGHT',
        'phone': '+1 (830) 450-3204',
        'address': '963 Lancaster Avenue, Choctaw, California, 4772',
        'about': 'Quis amet non aliqua veniam. Culpa et excepteur cillum consectetur id eiusmod irure. Do proident velit et nisi officia.',
        'registered': 'Friday, August 1, 2014 8:58 PM',
        'latitude': '-79.892982',
        'department': 'Бухгалтерия'
      }]
    }, {
      'name': 'Ramsey Riggs',
      'tasks': [{
        'user': 'Ramsey Riggs',
        'status': 'В очереди',
        'balance': '$2,341.35',
        'company': 'REALMO',
        'phone': '+1 (948) 456-3455',
        'address': '471 Sullivan Street, Welch, Kansas, 8180',
        'about': 'Ipsum ea nulla dolore laborum labore proident consequat. Cillum pariatur occaecat ea ut ut. Velit adipisicing dolor ex mollit enim cupidatat veniam exercitation ad Lorem incididunt magna.',
        'registered': 'Sunday, October 2, 2016 1:26 AM',
        'latitude': '18.381174',
        'department': 'Бухгалтерия'
      }]
    }, {
      'name': 'Hodges Booth',
      'tasks': [{
        'user': 'Hodges Booth',
        'status': 'В очереди',
        'balance': '$3,626.10',
        'company': 'APPLIDECK',
        'phone': '+1 (807) 478-3747',
        'address': '621 Horace Court, Benson, Alabama, 2395',
        'about': 'Irure in pariatur voluptate incididunt exercitation. Exercitation Lorem nostrud aliqua cillum labore eu laborum elit ut veniam ipsum adipisicing sit Lorem. Exercitation labore voluptate magna velit deserunt duis. Do qui mollit consequat veniam id proident esse. Laborum deserunt ipsum nisi laborum velit sunt.',
        'registered': 'Saturday, May 2, 2015 5:52 PM',
        'latitude': '-61.175001',
        'department': 'Бухгалтерия'
      }, {
        'user': 'Hodges Booth',
        'status': 'Отменено',
        'balance': '$3,004.00',
        'company': 'ZYTRAX',
        'phone': '+1 (888) 465-3407',
        'address': '966 Clinton Street, Lavalette, Iowa, 3760',
        'about': 'Anim officia adipisicing culpa nostrud enim consequat irure aliquip cupidatat excepteur Lorem laboris ea. Proident exercitation cillum do velit ipsum excepteur tempor cupidatat ea Lorem quis consectetur sint nulla. Minim ut consectetur qui Lorem. Enim consectetur adipisicing duis cillum. Ullamco proident incididunt anim sunt dolor aliquip ut ipsum.',
        'registered': 'Thursday, December 12, 2019 4:30 AM',
        'latitude': '56.121068',
        'department': 'Бухгалтерия'
      }, {
        'user': 'Hodges Booth',
        'status': 'Отменено',
        'balance': '$1,839.99',
        'company': 'COSMOSIS',
        'phone': '+1 (855) 461-3630',
        'address': '309 Elm Avenue, Cedarville, Virgin Islands, 5867',
        'about': 'Adipisicing aute et elit qui Lorem proident officia reprehenderit ipsum veniam laboris ea. Aute dolor Lorem ut tempor nostrud magna aliquip id dolor id elit. Consequat id elit exercitation sint sit ullamco eiusmod. Non enim adipisicing incididunt id in incididunt dolore in. Ea ea anim esse adipisicing id irure. Ea sit culpa labore magna pariatur. Incididunt ex sint ipsum do ut ullamco.',
        'registered': 'Sunday, June 26, 2016 11:16 AM',
        'latitude': '1.019523',
        'department': 'Бухгалтерия'
      }]
    }, {
      'name': 'Pauline Byrd',
      'tasks': [{
        'user': 'Pauline Byrd',
        'status': 'Отменено',
        'balance': '$3,697.20',
        'company': 'EVENTEX',
        'phone': '+1 (997) 592-2286',
        'address': '174 Elton Street, Drytown, Virginia, 6717',
        'about': 'Nisi consequat est tempor ipsum excepteur aliqua tempor dolor consectetur labore aute dolor eu mollit. Sit id veniam excepteur commodo commodo dolor eu. Ut in incididunt nostrud officia et sint id dolor esse velit ullamco minim. Enim eiusmod magna deserunt aute fugiat labore tempor ea ullamco. Officia ad adipisicing aute officia officia amet officia adipisicing mollit aliquip ut ut esse. Laborum deserunt culpa ipsum id tempor reprehenderit. Do velit proident eu et ut quis occaecat dolor amet elit.',
        'registered': 'Thursday, February 20, 2014 8:43 PM',
        'latitude': '-46.477093',
        'department': 'Бухгалтерия'
      }, {
        'user': 'Pauline Byrd',
        'status': 'В очереди',
        'balance': '$2,112.96',
        'company': 'SOPRANO',
        'phone': '+1 (935) 513-3014',
        'address': '574 Suydam Street, Mahtowa, California, 6122',
        'about': 'Aute enim sunt aliqua eu commodo labore Lorem non culpa est esse non officia deserunt. Ipsum consectetur velit dolor laborum. Non officia exercitation veniam enim ullamco labore laboris veniam est enim.',
        'registered': 'Sunday, August 20, 2017 1:53 AM',
        'latitude': '60.005251',
        'department': 'Бухгалтерия'
      }, {
        'user': 'Pauline Byrd',
        'status': 'Завершено',
        'balance': '$3,284.32',
        'company': 'DIGIFAD',
        'phone': '+1 (962) 554-2164',
        'address': '237 Boynton Place, Tooleville, Mississippi, 8185',
        'about': 'Lorem adipisicing excepteur excepteur deserunt. Cillum et eiusmod exercitation enim voluptate elit occaecat amet ut eiusmod do Lorem non sint. Nostrud dolor consequat excepteur et sint veniam laboris.',
        'registered': 'Monday, March 31, 2014 6:59 PM',
        'latitude': '-27.281094',
        'department': 'Бухгалтерия'
      }, {
        'user': 'Pauline Byrd',
        'status': 'В очереди',
        'balance': '$3,993.94',
        'company': 'PLASTO',
        'phone': '+1 (996) 502-3945',
        'address': '966 Williams Court, Kaka, District Of Columbia, 7788',
        'about': 'Excepteur enim velit consequat officia et quis excepteur labore ea officia. Ullamco labore id occaecat non aute incididunt irure. Nisi velit est ex veniam ad nisi sunt adipisicing labore aliquip aliqua. Sunt id eiusmod laboris amet sunt labore Lorem dolore ea qui tempor.',
        'registered': 'Wednesday, March 16, 2016 8:30 AM',
        'latitude': '-9.590252',
        'department': 'Бухгалтерия'
      }, {
        'user': 'Pauline Byrd',
        'status': 'В очереди',
        'balance': '$2,135.05',
        'company': 'UPLINX',
        'phone': '+1 (919) 424-3676',
        'address': '265 Clark Street, Bethpage, Oregon, 8216',
        'about': 'Ullamco culpa magna commodo in irure id duis. Consectetur eu tempor dolor adipisicing et. Irure qui quis aliqua id culpa velit fugiat ea aliqua ut.',
        'registered': 'Saturday, June 7, 2014 5:37 AM',
        'latitude': '22.473811',
        'department': 'Бухгалтерия'
      }, {
        'user': 'Pauline Byrd',
        'status': 'В очереди',
        'balance': '$2,739.16',
        'company': 'KATAKANA',
        'phone': '+1 (835) 554-2305',
        'address': '689 Hill Street, Kieler, Marshall Islands, 3803',
        'about': 'Aliqua ad sunt et ex nostrud sunt exercitation nostrud cillum adipisicing ex culpa excepteur. Occaecat dolore adipisicing dolor duis pariatur labore anim esse commodo velit. Eiusmod sunt nisi elit consectetur consequat irure aliqua tempor esse dolor non.',
        'registered': 'Thursday, January 26, 2017 12:32 AM',
        'latitude': '68.09489',
        'department': 'Бухгалтерия'
      }]
    }]
  }, {
    'name': 'Производственный',
    'children': [{
      'name': 'Middleton Sellers',
      'tasks': [{
        'user': 'Middleton Sellers',
        'status': 'В очереди',
        'balance': '$1,324.69',
        'company': 'GAPTEC',
        'phone': '+1 (814) 577-2603',
        'address': '965 Flatbush Avenue, Navarre, New Mexico, 4539',
        'about': 'Minim excepteur cupidatat incididunt ut reprehenderit aliqua consequat ut adipisicing culpa cillum. Do ad anim sunt dolore. Excepteur veniam deserunt laborum magna velit sunt aliquip nisi et. Occaecat commodo magna aliquip do in labore ipsum. Pariatur eiusmod irure mollit incididunt voluptate.',
        'registered': 'Thursday, February 26, 2015 9:56 AM',
        'latitude': '42.098065',
        'department': 'Производственный'
      }, {
        'user': 'Middleton Sellers',
        'status': 'Завершено',
        'balance': '$1,069.06',
        'company': 'PROXSOFT',
        'phone': '+1 (855) 528-2294',
        'address': '344 Pierrepont Place, Campo, Nevada, 309',
        'about': 'Consequat quis commodo mollit elit mollit. Adipisicing voluptate ipsum magna quis nostrud pariatur aute do nisi sunt. In enim proident commodo nostrud tempor non duis esse voluptate ad esse nulla minim fugiat. Velit ad dolore magna sunt eiusmod eiusmod cillum nostrud consectetur amet. Lorem eiusmod nostrud sit laboris ullamco minim ex cillum ut laborum incididunt sint irure non.',
        'registered': 'Monday, June 18, 2018 10:17 AM',
        'latitude': '-67.322309',
        'department': 'Производственный'
      }, {
        'user': 'Middleton Sellers',
        'status': 'В работе',
        'balance': '$2,902.95',
        'company': 'SOFTMICRO',
        'phone': '+1 (908) 491-3999',
        'address': '723 Evans Street, Roeville, Alabama, 3988',
        'about': 'Minim reprehenderit sint aliquip dolor. Aliquip in ad anim fugiat non commodo laboris. Labore esse id eu et non. Voluptate culpa ut cupidatat mollit officia duis consequat. Ullamco exercitation exercitation nostrud esse aute aliqua voluptate anim. Ut exercitation irure magna labore sit proident anim non adipisicing. Exercitation non sunt laborum in esse labore veniam duis officia eiusmod nulla sint voluptate elit.',
        'registered': 'Thursday, November 21, 2019 11:14 AM',
        'latitude': '50.317999',
        'department': 'Производственный'
      }]
    }, {
      'name': 'Ester Martin',
      'tasks': [{
        'user': 'Ester Martin',
        'status': 'В очереди',
        'balance': '$2,310.40',
        'company': 'KEENGEN',
        'phone': '+1 (950) 531-2573',
        'address': '833 Coleman Street, Sims, Michigan, 8068',
        'about': 'Aliqua sunt eiusmod officia qui sit adipisicing non deserunt do sunt aute adipisicing qui. Est ipsum ea nisi est qui duis. Anim minim do non sunt nisi sit voluptate deserunt quis aliqua minim fugiat.',
        'registered': 'Tuesday, October 30, 2018 12:49 AM',
        'latitude': '89.378109',
        'department': 'Производственный'
      }, {
        'user': 'Ester Martin',
        'status': 'Отменено',
        'balance': '$1,068.18',
        'company': 'KNOWLYSIS',
        'phone': '+1 (827) 546-3270',
        'address': '703 Highland Place, Soudan, Nebraska, 3135',
        'about': 'Laborum minim aute culpa amet labore. Laboris est velit Lorem nisi eiusmod consequat consectetur irure mollit. Commodo eu cupidatat Lorem ullamco nisi nostrud dolor esse consequat aliquip cupidatat. Ipsum tempor nostrud amet eu aute eiusmod occaecat. Laboris nostrud do do ea proident do magna nisi elit ex enim. Ea minim est ipsum veniam ullamco officia minim amet exercitation dolor labore commodo eiusmod labore.',
        'registered': 'Monday, July 8, 2019 9:57 PM',
        'latitude': '69.549781',
        'department': 'Производственный'
      }, {
        'user': 'Ester Martin',
        'status': 'Отменено',
        'balance': '$2,359.67',
        'company': 'ECRATIC',
        'phone': '+1 (981) 400-2845',
        'address': '965 Bay Avenue, Warsaw, Arkansas, 6351',
        'about': 'Qui nostrud eu dolore deserunt deserunt minim exercitation. Eu non ex sint tempor et pariatur amet. Esse ad consectetur nulla voluptate do aute veniam magna excepteur anim fugiat esse.',
        'registered': 'Sunday, January 19, 2020 8:08 AM',
        'latitude': '19.108678',
        'department': 'Производственный'
      }, {
        'user': 'Ester Martin',
        'status': 'В очереди',
        'balance': '$1,562.48',
        'company': 'EURON',
        'phone': '+1 (871) 471-3190',
        'address': '288 Nichols Avenue, Boomer, Maine, 3807',
        'about': 'Tempor eu sint ipsum commodo reprehenderit ea laboris culpa. In consectetur excepteur officia aute sunt Lorem non culpa cillum. Pariatur dolore nulla officia esse aliquip. Cillum veniam consectetur commodo anim reprehenderit ad ea Lorem. Cillum Lorem eu sit ipsum enim minim aliqua ad incididunt. Ad cupidatat sunt ad fugiat ad cupidatat anim.',
        'registered': 'Monday, September 21, 2015 6:27 PM',
        'latitude': '-0.449921',
        'department': 'Производственный'
      }]
    }]
  }, {
    'name': 'Отдел закупок',
    'children': [{
      'name': 'Kathryn Dale',
      'tasks': [{
        'user': 'Kathryn Dale',
        'status': 'Завершено',
        'balance': '$2,716.12',
        'company': 'GEEKWAGON',
        'phone': '+1 (976) 508-3937',
        'address': '191 Stryker Court, Forbestown, Alaska, 8521',
        'about': 'Officia exercitation ea cillum pariatur fugiat labore exercitation incididunt. Magna sit aliquip cillum Lorem cillum cillum aliquip incididunt culpa excepteur id. Eu culpa do non deserunt in. Ipsum ipsum sit adipisicing exercitation. Cillum mollit officia Lorem nisi voluptate sit duis exercitation enim eu eu.',
        'registered': 'Friday, August 11, 2017 4:15 AM',
        'latitude': '-77.932184',
        'department': 'Отдел закупок'
      }, {
        'user': 'Kathryn Dale',
        'status': 'В очереди',
        'balance': '$1,484.10',
        'company': 'OBONES',
        'phone': '+1 (834) 463-2604',
        'address': '512 Lafayette Avenue, Kiskimere, Missouri, 6825',
        'about': 'Est esse eiusmod culpa ea et mollit Lorem duis commodo culpa id officia deserunt nostrud. Fugiat excepteur enim ut qui ullamco ea est voluptate excepteur amet ut occaecat est. Aliquip magna irure esse irure elit eu.',
        'registered': 'Sunday, May 14, 2017 12:37 AM',
        'latitude': '12.845431',
        'department': 'Отдел закупок'
      }, {
        'user': 'Kathryn Dale',
        'status': 'В работе',
        'balance': '$1,878.09',
        'company': 'HOTCAKES',
        'phone': '+1 (807) 577-3769',
        'address': '885 Wogan Terrace, Roosevelt, Delaware, 8820',
        'about': 'Id pariatur duis irure aute voluptate quis officia eu nostrud enim eiusmod laboris. Labore fugiat esse est amet dolor nostrud id. Laboris in esse est ad aliqua tempor laborum ea Lorem eiusmod id reprehenderit.',
        'registered': 'Thursday, February 1, 2018 5:49 AM',
        'latitude': '57.156758',
        'department': 'Отдел закупок'
      }, {
        'user': 'Kathryn Dale',
        'status': 'В работе',
        'balance': '$2,759.60',
        'company': 'UNCORP',
        'phone': '+1 (842) 426-3511',
        'address': '316 Gain Court, Bowie, Indiana, 9453',
        'about': 'Quis nulla proident enim do. Aliquip eiusmod et qui aliqua est. Nostrud est aliquip est non laborum pariatur proident id sunt adipisicing esse. Consectetur amet voluptate est nostrud est velit sunt esse occaecat aliqua dolor mollit ad eu. Fugiat quis irure nulla sunt mollit duis sunt ex tempor. Eu nulla eu nisi ex sunt non. Ex exercitation ipsum incididunt dolor cillum consequat nisi enim et.',
        'registered': 'Saturday, January 14, 2017 12:12 PM',
        'latitude': '66.73654',
        'department': 'Отдел закупок'
      }]
    }, {
      'name': 'Grace Cooper',
      'tasks': [{
        'user': 'Grace Cooper',
        'status': 'Отменено',
        'balance': '$1,384.93',
        'company': 'ZOMBOID',
        'phone': '+1 (841) 427-2846',
        'address': '276 Coles Street, Bergoo, Tennessee, 5785',
        'about': 'Ullamco eu occaecat mollit eu proident non eu magna velit minim qui eiusmod fugiat amet. Ullamco ea aute occaecat nulla velit est in ad fugiat minim labore proident veniam reprehenderit. Nulla aliqua est consequat ipsum elit excepteur amet fugiat. Aliquip nostrud laborum deserunt aliquip nostrud eu consequat do est culpa nisi dolore veniam. Qui irure tempor elit mollit ullamco culpa dolore deserunt nulla deserunt esse laborum nulla.',
        'registered': 'Sunday, March 22, 2015 12:14 PM',
        'latitude': '-12.717297',
        'department': 'Отдел закупок'
      }, {
        'user': 'Grace Cooper',
        'status': 'В работе',
        'balance': '$1,810.11',
        'company': 'BUZZWORKS',
        'phone': '+1 (861) 409-3002',
        'address': '739 Kenilworth Place, Salix, North Carolina, 7630',
        'about': 'Enim do irure dolor velit veniam tempor fugiat mollit cupidatat. Laboris ipsum do pariatur duis sunt sunt aliquip laborum est. Consequat irure ipsum irure eiusmod Lorem qui commodo officia non pariatur ullamco.',
        'registered': 'Sunday, January 4, 2015 9:01 AM',
        'latitude': '-77.654579',
        'department': 'Отдел закупок'
      }]
    }, {
      'name': 'Christensen Juarez',
      'tasks': [{
        'user': 'Christensen Juarez',
        'status': 'Отменено',
        'balance': '$2,282.88',
        'company': 'DOGTOWN',
        'phone': '+1 (910) 416-3864',
        'address': '159 Cooke Court, Silkworth, Kentucky, 9622',
        'about': 'Velit tempor Lorem et fugiat et velit fugiat id. Reprehenderit esse officia reprehenderit labore deserunt occaecat occaecat labore anim exercitation. Cillum proident laboris eiusmod cupidatat nostrud ea esse deserunt incididunt cillum adipisicing. Sit sunt cupidatat reprehenderit reprehenderit aliqua reprehenderit exercitation velit. Laboris ea exercitation occaecat do eiusmod magna cupidatat commodo duis id. Magna incididunt non sint deserunt sint velit consectetur eiusmod ipsum aliqua.',
        'registered': 'Thursday, July 2, 2015 9:44 PM',
        'latitude': '-34.723013',
        'department': 'Отдел закупок'
      }, {
        'user': 'Christensen Juarez',
        'status': 'В работе',
        'balance': '$3,793.57',
        'company': 'VENDBLEND',
        'phone': '+1 (978) 559-3510',
        'address': '912 Alton Place, Thomasville, Connecticut, 1654',
        'about': 'Nostrud excepteur laborum esse aliqua do irure cupidatat id excepteur ut enim. Id ea incididunt id sint fugiat ad. Lorem dolor mollit voluptate quis velit cupidatat. Ea nulla ea minim laborum cillum aliquip minim pariatur. Occaecat id et in esse cupidatat esse minim. Nulla sint officia dolore reprehenderit quis incididunt occaecat proident in. Excepteur labore ipsum sunt deserunt dolore veniam ea et reprehenderit.',
        'registered': 'Tuesday, October 21, 2014 9:19 PM',
        'latitude': '-41.788516',
        'department': 'Отдел закупок'
      }, {
        'user': 'Christensen Juarez',
        'status': 'В работе',
        'balance': '$2,567.84',
        'company': 'PEARLESEX',
        'phone': '+1 (833) 556-2383',
        'address': '282 Perry Place, Ballico, Guam, 8641',
        'about': 'Cillum occaecat voluptate deserunt amet. Quis ex nostrud ad elit nostrud mollit non deserunt elit. Labore quis ipsum aliquip irure deserunt nostrud eiusmod tempor consequat veniam dolore est. Cillum nulla nostrud do sint eiusmod sint labore sunt eu ipsum ad. Irure consectetur adipisicing culpa nisi adipisicing consequat officia tempor esse. Lorem voluptate ex et laboris mollit commodo id labore ullamco aute aliqua sunt velit. Ea ut labore ullamco mollit laboris incididunt sit duis consequat enim commodo mollit.',
        'registered': 'Tuesday, September 20, 2016 3:56 AM',
        'latitude': '-39.745907',
        'department': 'Отдел закупок'
      }, {
        'user': 'Christensen Juarez',
        'status': 'В очереди',
        'balance': '$2,481.45',
        'company': 'KYAGORO',
        'phone': '+1 (918) 547-2288',
        'address': '213 Cornelia Street, Cobbtown, Georgia, 8137',
        'about': 'Culpa exercitation laborum in ex esse proident minim cillum ipsum veniam sit labore ad. Enim pariatur aliqua eiusmod consectetur do. Anim incididunt sit dolore elit velit non deserunt nulla tempor laborum laboris voluptate incididunt. Velit mollit ut laborum deserunt aliqua fugiat. Laborum anim exercitation duis aliquip duis ex proident elit adipisicing in aliqua commodo duis ea.',
        'registered': 'Sunday, November 16, 2014 4:24 AM',
        'latitude': '-29.455322',
        'department': 'Отдел закупок'
      }]
    }, {
      'name': 'Kristi Long',
      'tasks': [{
        'user': 'Kristi Long',
        'status': 'В работе',
        'balance': '$1,829.10',
        'company': 'VALPREAL',
        'phone': '+1 (985) 560-2560',
        'address': '240 Prospect Place, Tibbie, Marshall Islands, 2950',
        'about': 'Labore ipsum dolor pariatur voluptate quis anim. Nostrud proident proident et id non duis incididunt commodo ea mollit ipsum. Ullamco cupidatat dolor incididunt laboris.',
        'registered': 'Thursday, April 7, 2016 8:19 PM',
        'latitude': '78.233677',
        'department': 'Отдел закупок'
      }, {
        'user': 'Kristi Long',
        'status': 'В работе',
        'balance': '$1,509.43',
        'company': 'ZOARERE',
        'phone': '+1 (872) 458-2181',
        'address': '566 Albemarle Terrace, Sultana, New Hampshire, 3619',
        'about': 'Dolore tempor ut eu et pariatur ipsum esse reprehenderit Lorem ex. Adipisicing minim velit voluptate eiusmod officia quis. Exercitation commodo nostrud duis excepteur labore mollit in reprehenderit ad.',
        'registered': 'Tuesday, August 29, 2017 10:08 AM',
        'latitude': '53.622224',
        'department': 'Отдел закупок'
      }, {
        'user': 'Kristi Long',
        'status': 'В работе',
        'balance': '$1,047.69',
        'company': 'XANIDE',
        'phone': '+1 (887) 461-3179',
        'address': '113 Barwell Terrace, Lacomb, Washington, 7661',
        'about': 'Dolore culpa deserunt velit incididunt cillum amet eiusmod eu pariatur irure labore eu sit. Dolor labore nulla commodo exercitation pariatur non consequat fugiat nulla laborum aliquip enim. Ut consectetur incididunt nisi incididunt ex eiusmod commodo dolore.',
        'registered': 'Friday, July 20, 2018 7:41 PM',
        'latitude': '43.193548',
        'department': 'Отдел закупок'
      }, {
        'user': 'Kristi Long',
        'status': 'В очереди',
        'balance': '$1,122.81',
        'company': 'UBERLUX',
        'phone': '+1 (848) 505-3742',
        'address': '846 Lawton Street, Barclay, Puerto Rico, 1737',
        'about': 'Ullamco ad amet id commodo irure ut mollit sint ullamco laboris cillum veniam. Ad velit elit veniam sunt. Duis ea commodo ipsum exercitation veniam mollit aliquip culpa sint duis occaecat voluptate. Est ea labore sit ipsum dolor ullamco exercitation elit eu cillum est in.',
        'registered': 'Friday, September 5, 2014 6:55 AM',
        'latitude': '-6.228822',
        'department': 'Отдел закупок'
      }, {
        'user': 'Kristi Long',
        'status': 'В очереди',
        'balance': '$1,821.19',
        'company': 'COLAIRE',
        'phone': '+1 (842) 495-3576',
        'address': '375 Bath Avenue, Wollochet, Virginia, 1126',
        'about': 'Sunt et pariatur excepteur voluptate quis irure id ad dolore proident dolore consequat consectetur. Mollit dolor ut nulla ex velit sit eu deserunt ipsum occaecat enim. Commodo duis enim officia sit quis. Exercitation exercitation incididunt cupidatat do aliqua laboris qui magna deserunt deserunt adipisicing. Nulla deserunt irure mollit laboris.',
        'registered': 'Friday, March 3, 2017 11:17 PM',
        'latitude': '-89.775211',
        'department': 'Отдел закупок'
      }]
    }, {
      'name': 'Mcmillan Carlson',
      'tasks': [{
        'user': 'Mcmillan Carlson',
        'status': 'В очереди',
        'balance': '$1,611.60',
        'company': 'INEAR',
        'phone': '+1 (900) 482-2834',
        'address': '306 Mill Avenue, Chautauqua, North Carolina, 6241',
        'about': 'Sint culpa fugiat elit cillum deserunt elit voluptate excepteur laboris ullamco dolor non minim. Voluptate dolore aliquip ex nostrud nisi quis laborum enim ex sit incididunt. Eiusmod cupidatat tempor nisi laboris tempor pariatur eiusmod sint qui. Excepteur dolore consequat incididunt tempor enim ex. Sit in mollit ex reprehenderit consectetur elit. Dolor occaecat mollit tempor elit in velit adipisicing nulla ex.',
        'registered': 'Friday, August 11, 2017 8:27 AM',
        'latitude': '-13.027891',
        'department': 'Отдел закупок'
      }]
    }, {
      'name': 'Baird Morris',
      'tasks': [{
        'user': 'Baird Morris',
        'status': 'Завершено',
        'balance': '$2,868.78',
        'company': 'NETBOOK',
        'phone': '+1 (970) 522-3463',
        'address': '490 Ebony Court, Roy, Minnesota, 1124',
        'about': 'Reprehenderit voluptate culpa do ullamco id nulla cillum elit labore. Id excepteur elit id occaecat proident sunt incididunt. Reprehenderit culpa nisi culpa amet dolore deserunt nulla irure est sit nisi ea amet. Consectetur ea et aliqua excepteur Lorem cupidatat culpa enim occaecat elit quis enim do commodo. Sit ea cillum cupidatat nisi id officia quis. Lorem veniam aliqua id nisi incididunt dolor dolore adipisicing. Consectetur magna proident id qui officia consequat non fugiat aute pariatur do fugiat id ullamco.',
        'registered': 'Wednesday, February 10, 2016 1:30 PM',
        'latitude': '-9.281107',
        'department': 'Отдел закупок'
      }, {
        'user': 'Baird Morris',
        'status': 'Отменено',
        'balance': '$2,492.70',
        'company': 'EARBANG',
        'phone': '+1 (958) 546-3951',
        'address': '929 Cass Place, Steinhatchee, Rhode Island, 8963',
        'about': 'Fugiat dolor nisi commodo aliqua. Ad ipsum aute pariatur consectetur laboris fugiat non culpa Lorem. Aliquip occaecat ex cupidatat duis.',
        'registered': 'Thursday, October 18, 2018 6:30 PM',
        'latitude': '79.703119',
        'department': 'Отдел закупок'
      }, {
        'user': 'Baird Morris',
        'status': 'В работе',
        'balance': '$3,483.35',
        'company': 'ZERBINA',
        'phone': '+1 (836) 514-2206',
        'address': '645 Canda Avenue, Fairmount, Wisconsin, 3308',
        'about': 'Consectetur aliquip nisi veniam ea voluptate elit ad laborum ex. Lorem ullamco occaecat ipsum cillum labore voluptate cillum aliqua ea eiusmod occaecat. Adipisicing ipsum velit esse velit minim quis fugiat ullamco. Quis nulla amet Lorem exercitation laboris ullamco laboris laborum adipisicing. Dolor sit ex eu aliquip et nisi qui enim anim.',
        'registered': 'Monday, May 18, 2020 6:38 AM',
        'latitude': '29.634981',
        'department': 'Отдел закупок'
      }, {
        'user': 'Baird Morris',
        'status': 'Отменено',
        'balance': '$1,168.74',
        'company': 'VURBO',
        'phone': '+1 (979) 585-3576',
        'address': '722 Village Court, Allamuchy, Hawaii, 4676',
        'about': 'Esse commodo amet dolor quis commodo irure voluptate in laborum incididunt fugiat. Incididunt aliquip dolore ullamco dolore proident commodo Lorem ipsum do irure. Consectetur anim irure velit irure ullamco amet consequat incididunt.',
        'registered': 'Saturday, November 9, 2019 6:19 PM',
        'latitude': '36.819548',
        'department': 'Отдел закупок'
      }]
    }, {
      'name': 'Boyer Harrison',
      'tasks': [{
        'user': 'Boyer Harrison',
        'status': 'Отменено',
        'balance': '$3,203.40',
        'company': 'HALAP',
        'phone': '+1 (932) 539-3420',
        'address': '865 Box Street, Fairhaven, New Jersey, 425',
        'about': 'Velit occaecat ullamco ex nostrud dolor cillum. Voluptate veniam id do anim occaecat nisi eu esse amet non esse culpa aliqua amet. Cupidatat deserunt id aliqua excepteur sit adipisicing voluptate veniam cupidatat sunt magna. Deserunt officia cupidatat ut sunt enim.',
        'registered': 'Tuesday, April 21, 2015 1:10 PM',
        'latitude': '62.959664',
        'department': 'Отдел закупок'
      }]
    }]
  }]
export const tasks =
    [
      {
        'user': 'Delia Bradshaw',
        'status': 'Отменено',
        'balance': '$2,761.38',
        'company': 'HINWAY',
        'phone': '+1 (835) 476-3127',
        'address': '860 Oak Street, Hiwasse, South Carolina, 7228',
        'about': 'Officia anim occaecat ex nostrud magna cillum nulla fugiat fugiat consequat nulla eu pariatur. Do laborum ex ad nostrud cillum incididunt nisi veniam pariatur culpa. Veniam culpa sunt adipisicing id duis consectetur sit.',
        'registered': 'Tuesday, February 17, 2015 10:44 PM',
        'latitude': '27.412977'
      },
      {
        'user': 'Tillman Stephenson',
        'status': 'Отменено',
        'balance': '$2,054.09',
        'company': 'NURALI',
        'phone': '+1 (924) 567-3709',
        'address': '518 Preston Court, Dawn, Idaho, 8949',
        'about': 'Est occaecat fugiat deserunt officia eu. Officia occaecat sunt tempor aliquip enim. Amet velit nisi deserunt ex. Nisi proident adipisicing eu duis adipisicing cillum et anim ullamco dolore adipisicing. Sit eu dolore laboris aliquip officia ullamco ex non reprehenderit. Dolore officia pariatur id pariatur esse proident enim. Nulla occaecat est commodo eu ullamco Lorem qui.',
        'registered': 'Thursday, January 14, 2016 12:10 PM',
        'latitude': '-86.063388'
      },
      {
        'user': 'Middleton Sellers',
        'status': 'В очереди',
        'balance': '$1,324.69',
        'company': 'GAPTEC',
        'phone': '+1 (814) 577-2603',
        'address': '965 Flatbush Avenue, Navarre, New Mexico, 4539',
        'about': 'Minim excepteur cupidatat incididunt ut reprehenderit aliqua consequat ut adipisicing culpa cillum. Do ad anim sunt dolore. Excepteur veniam deserunt laborum magna velit sunt aliquip nisi et. Occaecat commodo magna aliquip do in labore ipsum. Pariatur eiusmod irure mollit incididunt voluptate.',
        'registered': 'Thursday, February 26, 2015 9:56 AM',
        'latitude': '42.098065'
      },
      {
        'user': 'Vilma Caldwell',
        'status': 'Отменено',
        'balance': '$2,405.71',
        'company': 'MUSANPOLY',
        'phone': '+1 (898) 423-3994',
        'address': '827 Nassau Avenue, Hiko, Maryland, 7236',
        'about': 'Anim adipisicing aute sit aute proident id. Reprehenderit consequat est consequat et culpa amet do amet. Ex minim ipsum ullamco cupidatat id est tempor et nulla. Proident aliqua excepteur id reprehenderit culpa. Nostrud ex culpa enim elit voluptate est voluptate incididunt irure. Aute nisi cupidatat laborum irure ex. Sit dolore aute quis deserunt qui fugiat consequat excepteur cupidatat sint mollit.',
        'registered': 'Friday, April 6, 2018 12:11 PM',
        'latitude': '-11.371784'
      },
      {
        'user': 'Frost Craig',
        'status': 'Завершено',
        'balance': '$3,594.83',
        'company': 'MATRIXITY',
        'phone': '+1 (896) 479-3087',
        'address': '960 Oceanic Avenue, Vernon, Guam, 4762',
        'about': 'Nostrud dolor irure anim incididunt culpa laborum Lorem. Dolore qui ea incididunt consectetur aliqua laborum. Sunt mollit veniam amet sunt quis ipsum anim duis elit culpa nulla proident sit. Culpa nisi nisi sunt pariatur irure. Excepteur aliqua ullamco sunt nulla voluptate eiusmod ad aliqua deserunt. Culpa sunt ipsum ullamco occaecat duis adipisicing aute aute enim aute. Incididunt amet consectetur non velit do consequat esse minim eu excepteur enim minim nostrud.',
        'registered': 'Tuesday, September 11, 2018 1:51 PM',
        'latitude': '41.127078'
      },
      {
        'user': 'Marla Manning',
        'status': 'Завершено',
        'balance': '$3,966.53',
        'company': 'BLANET',
        'phone': '+1 (920) 453-3754',
        'address': '690 Buffalo Avenue, Gwynn, Wisconsin, 3282',
        'about': 'Anim minim non eu sint labore commodo enim eiusmod irure nostrud minim nulla irure labore. Dolore anim irure ex laboris incididunt dolore. Anim commodo do consequat duis quis magna id ad sunt cillum deserunt.',
        'registered': 'Monday, June 19, 2017 9:54 PM',
        'latitude': '-61.631967'
      },
      {
        'user': 'Thompson Heath',
        'status': 'Завершено',
        'balance': '$2,100.20',
        'company': 'OVERPLEX',
        'phone': '+1 (975) 448-2264',
        'address': '195 Lake Street, Movico, Oregon, 927',
        'about': 'Et elit et quis id ut ut elit officia cupidatat reprehenderit pariatur ea dolor fugiat. Culpa sint pariatur do velit culpa ex occaecat et sit cupidatat eiusmod reprehenderit fugiat aliquip. Est deserunt nisi magna cupidatat et non. Aute ad et esse excepteur officia ipsum commodo enim irure enim cillum dolor. Commodo qui ea dolore adipisicing sint esse fugiat voluptate consectetur aliquip ullamco laboris mollit. Voluptate anim esse excepteur irure ullamco adipisicing anim minim sint nisi tempor culpa dolore.',
        'registered': 'Monday, April 13, 2015 9:43 PM',
        'latitude': '40.864245'
      },
      {
        'user': 'Frost Craig',
        'status': 'В работе',
        'balance': '$3,294.24',
        'company': 'GRONK',
        'phone': '+1 (840) 467-3866',
        'address': '770 Truxton Street, Courtland, Maine, 9756',
        'about': 'Voluptate nostrud incididunt occaecat eu commodo adipisicing exercitation tempor. Incididunt reprehenderit magna minim consequat nostrud non. Occaecat veniam elit officia non sunt aliquip irure laboris.',
        'registered': 'Monday, June 17, 2019 11:14 PM',
        'latitude': '-87.91548'
      },
      {
        'user': 'Mcmillan Carlson',
        'status': 'В очереди',
        'balance': '$1,611.60',
        'company': 'INEAR',
        'phone': '+1 (900) 482-2834',
        'address': '306 Mill Avenue, Chautauqua, North Carolina, 6241',
        'about': 'Sint culpa fugiat elit cillum deserunt elit voluptate excepteur laboris ullamco dolor non minim. Voluptate dolore aliquip ex nostrud nisi quis laborum enim ex sit incididunt. Eiusmod cupidatat tempor nisi laboris tempor pariatur eiusmod sint qui. Excepteur dolore consequat incididunt tempor enim ex. Sit in mollit ex reprehenderit consectetur elit. Dolor occaecat mollit tempor elit in velit adipisicing nulla ex.',
        'registered': 'Friday, August 11, 2017 8:27 AM',
        'latitude': '-13.027891'
      },
      {
        'user': 'Mara Lewis',
        'status': 'Отменено',
        'balance': '$1,078.55',
        'company': 'SCENTY',
        'phone': '+1 (801) 455-2702',
        'address': '871 Rost Place, Hayes, Illinois, 5044',
        'about': 'Consequat laboris occaecat fugiat consectetur. Adipisicing eu dolor voluptate est fugiat eu id qui incididunt sunt esse est consequat aliqua. Duis et tempor et deserunt laboris magna sint labore ut amet. Esse laboris irure labore id aliquip veniam fugiat amet deserunt eu ullamco officia. Quis quis deserunt officia aliqua qui ut deserunt velit sint qui cillum ullamco.',
        'registered': 'Tuesday, September 11, 2018 9:22 AM',
        'latitude': '33.956828'
      },
      {
        'user': 'Vickie Burke',
        'status': 'В работе',
        'balance': '$1,151.56',
        'company': 'COMTRAK',
        'phone': '+1 (974) 545-2126',
        'address': '902 Arlington Place, Bordelonville, New York, 4594',
        'about': 'Consequat id commodo id sunt irure aliqua officia voluptate ullamco eiusmod ut. Amet dolor incididunt elit minim cupidatat nostrud culpa pariatur officia occaecat. Cupidatat tempor labore nulla anim.',
        'registered': 'Wednesday, July 29, 2015 10:21 AM',
        'latitude': '25.05401'
      },
      {
        'user': 'Thompson Heath',
        'status': 'Завершено',
        'balance': '$2,543.18',
        'company': 'COWTOWN',
        'phone': '+1 (944) 562-2459',
        'address': '296 Willmohr Street, Kent, Indiana, 4890',
        'about': 'Officia ad commodo aute est est. Culpa labore et do cillum pariatur occaecat culpa quis id irure. Elit aute irure qui qui culpa excepteur Lorem sit nisi consectetur duis.',
        'registered': 'Sunday, March 1, 2015 5:49 AM',
        'latitude': '-77.445832'
      },
      {
        'user': 'Esmeralda Underwood',
        'status': 'Завершено',
        'balance': '$2,597.04',
        'company': 'VANTAGE',
        'phone': '+1 (979) 425-2586',
        'address': '416 Claver Place, Sutton, Pennsylvania, 6761',
        'about': 'Enim ullamco deserunt id in nulla dolore voluptate nostrud sint commodo cillum. Consectetur ut tempor ad veniam minim. Excepteur ad occaecat voluptate tempor et. Cupidatat pariatur cupidatat dolore minim aute aliqua culpa qui cupidatat proident tempor excepteur quis eu.',
        'registered': 'Saturday, June 9, 2018 1:43 AM',
        'latitude': '-15.055972'
      },
      {
        'user': 'Kristi Long',
        'status': 'В работе',
        'balance': '$1,829.10',
        'company': 'VALPREAL',
        'phone': '+1 (985) 560-2560',
        'address': '240 Prospect Place, Tibbie, Marshall Islands, 2950',
        'about': 'Labore ipsum dolor pariatur voluptate quis anim. Nostrud proident proident et id non duis incididunt commodo ea mollit ipsum. Ullamco cupidatat dolor incididunt laboris.',
        'registered': 'Thursday, April 7, 2016 8:19 PM',
        'latitude': '78.233677'
      },
      {
        'user': 'Vilma Caldwell',
        'status': 'В работе',
        'balance': '$1,751.97',
        'company': 'ACCIDENCY',
        'phone': '+1 (820) 497-3704',
        'address': '354 Greene Avenue, Martinsville, Federated States Of Micronesia, 4532',
        'about': 'Enim eu est irure ut sit aute mollit pariatur quis tempor minim veniam proident. Esse do sint sunt et. Cupidatat qui eu velit Lorem aliqua nulla culpa veniam. Anim nostrud eu id esse. Proident do ullamco ut duis cillum aliqua cillum.',
        'registered': 'Tuesday, October 29, 2019 11:23 AM',
        'latitude': '-29.369235'
      },
      {
        'user': 'Kathryn Dale',
        'status': 'Завершено',
        'balance': '$2,716.12',
        'company': 'GEEKWAGON',
        'phone': '+1 (976) 508-3937',
        'address': '191 Stryker Court, Forbestown, Alaska, 8521',
        'about': 'Officia exercitation ea cillum pariatur fugiat labore exercitation incididunt. Magna sit aliquip cillum Lorem cillum cillum aliquip incididunt culpa excepteur id. Eu culpa do non deserunt in. Ipsum ipsum sit adipisicing exercitation. Cillum mollit officia Lorem nisi voluptate sit duis exercitation enim eu eu.',
        'registered': 'Friday, August 11, 2017 4:15 AM',
        'latitude': '-77.932184'
      },
      {
        'user': 'Sandra Floyd',
        'status': 'Завершено',
        'balance': '$3,293.14',
        'company': 'SPRINGBEE',
        'phone': '+1 (970) 560-2575',
        'address': '515 Eaton Court, Elliott, Vermont, 6993',
        'about': 'Fugiat quis labore dolor ut cillum mollit. Velit dolor culpa nostrud deserunt ut ut incididunt ut. Esse voluptate ut ea occaecat. Culpa dolore aliqua occaecat qui dolor.',
        'registered': 'Wednesday, November 12, 2014 6:46 AM',
        'latitude': '39.958969'
      },
      {
        'user': 'Pamela Knapp',
        'status': 'В работе',
        'balance': '$3,983.83',
        'company': 'PORTICA',
        'phone': '+1 (900) 502-3978',
        'address': '389 Newton Street, Belfair, Nevada, 4086',
        'about': 'Nulla excepteur excepteur anim magna ex Lorem amet in aliquip commodo deserunt id ipsum enim. Amet mollit sint proident est duis aute. Do esse exercitation culpa culpa cillum aute labore deserunt mollit exercitation pariatur non cillum.',
        'registered': 'Wednesday, April 23, 2014 4:40 PM',
        'latitude': '76.014662'
      },
      {
        'user': 'Thompson Heath',
        'status': 'Отменено',
        'balance': '$1,605.56',
        'company': 'DENTREX',
        'phone': '+1 (971) 401-2774',
        'address': '971 Newport Street, Holtville, Virgin Islands, 869',
        'about': 'Occaecat enim consectetur velit deserunt laboris est ex velit proident officia. Officia eiusmod enim eu labore nulla laborum aliqua elit quis ullamco eu veniam exercitation pariatur. Pariatur fugiat exercitation consectetur consectetur irure excepteur velit labore magna ipsum officia occaecat. Nisi dolor aliquip consequat nostrud. Aliqua in voluptate voluptate ut elit id enim deserunt irure anim adipisicing irure.',
        'registered': 'Friday, June 1, 2018 9:53 PM',
        'latitude': '-57.664961'
      },
      {
        'user': 'Lesley Beard',
        'status': 'В очереди',
        'balance': '$3,525.13',
        'company': 'COMVEY',
        'phone': '+1 (924) 543-2120',
        'address': '876 Leonora Court, Stewart, Florida, 758',
        'about': 'Aliquip exercitation cupidatat dolor est elit. Reprehenderit sunt ad esse incididunt consequat et tempor. Irure culpa consequat ipsum cupidatat officia irure enim mollit laborum eiusmod. Et elit laboris laboris eu id. Irure tempor dolor velit ullamco ea voluptate fugiat consectetur proident eu excepteur.',
        'registered': 'Friday, January 27, 2017 3:57 PM',
        'latitude': '62.144234'
      },
      {
        'user': 'Ester Martin',
        'status': 'В очереди',
        'balance': '$2,310.40',
        'company': 'KEENGEN',
        'phone': '+1 (950) 531-2573',
        'address': '833 Coleman Street, Sims, Michigan, 8068',
        'about': 'Aliqua sunt eiusmod officia qui sit adipisicing non deserunt do sunt aute adipisicing qui. Est ipsum ea nisi est qui duis. Anim minim do non sunt nisi sit voluptate deserunt quis aliqua minim fugiat.',
        'registered': 'Tuesday, October 30, 2018 12:49 AM',
        'latitude': '89.378109'
      },
      {
        'user': 'Stephanie Stanton',
        'status': 'В работе',
        'balance': '$1,628.79',
        'company': 'MEDESIGN',
        'phone': '+1 (811) 457-2713',
        'address': '104 Bay Street, Floriston, Washington, 9283',
        'about': 'Sunt enim laborum cupidatat et Lorem dolor minim sunt mollit tempor. Tempor exercitation mollit do aute ut sint cillum cillum officia ipsum labore anim ipsum. Irure nisi quis consequat amet culpa minim non. Dolor ipsum dolore consectetur enim id aute ad.',
        'registered': 'Tuesday, January 20, 2015 8:38 AM',
        'latitude': '-6.861642'
      },
      {
        'user': 'Chasity Mason',
        'status': 'В работе',
        'balance': '$2,472.29',
        'company': 'TELEQUIET',
        'phone': '+1 (917) 462-2065',
        'address': '599 Cambridge Place, Chical, Georgia, 2446',
        'about': 'Mollit dolor ipsum voluptate proident eiusmod sunt nostrud magna mollit culpa irure amet ullamco. Dolore nostrud laborum mollit sunt do cupidatat commodo. Mollit nulla dolor ut ipsum aute commodo esse Lorem elit magna proident veniam. Id in ex ea veniam Lorem Lorem quis do quis voluptate exercitation aliquip dolore id.',
        'registered': 'Tuesday, October 29, 2019 3:44 PM',
        'latitude': '20.971382'
      },
      {
        'user': 'Estella Mckay',
        'status': 'В работе',
        'balance': '$2,282.48',
        'company': 'ELPRO',
        'phone': '+1 (907) 403-3522',
        'address': '974 Gold Street, Itmann, Puerto Rico, 3709',
        'about': 'Irure consequat laborum quis enim ex sit proident nisi duis cupidatat aliquip ad irure excepteur. Duis ipsum est proident esse exercitation. Occaecat Lorem cupidatat commodo ad sint sint amet nisi fugiat duis. Occaecat dolore commodo aliquip irure anim. Fugiat velit et labore ad sit incididunt cillum fugiat est nulla in ad dolore.',
        'registered': 'Tuesday, June 3, 2014 12:18 PM',
        'latitude': '41.472851'
      },
      {
        'user': 'Ester Martin',
        'status': 'Отменено',
        'balance': '$1,068.18',
        'company': 'KNOWLYSIS',
        'phone': '+1 (827) 546-3270',
        'address': '703 Highland Place, Soudan, Nebraska, 3135',
        'about': 'Laborum minim aute culpa amet labore. Laboris est velit Lorem nisi eiusmod consequat consectetur irure mollit. Commodo eu cupidatat Lorem ullamco nisi nostrud dolor esse consequat aliquip cupidatat. Ipsum tempor nostrud amet eu aute eiusmod occaecat. Laboris nostrud do do ea proident do magna nisi elit ex enim. Ea minim est ipsum veniam ullamco officia minim amet exercitation dolor labore commodo eiusmod labore.',
        'registered': 'Monday, July 8, 2019 9:57 PM',
        'latitude': '69.549781'
      },
      {
        'user': 'Vickie Burke',
        'status': 'Отменено',
        'balance': '$1,112.56',
        'company': 'SNIPS',
        'phone': '+1 (844) 473-2650',
        'address': '459 Roosevelt Place, Northridge, South Dakota, 2549',
        'about': 'Mollit magna cillum ipsum qui enim laborum aute non eu et qui enim. Veniam incididunt qui aliqua consectetur aute eiusmod incididunt esse voluptate do sint aute exercitation. Sunt cupidatat est ullamco qui incididunt.',
        'registered': 'Sunday, February 23, 2014 8:16 AM',
        'latitude': '1.129789'
      },
      {
        'user': 'Barton Torres',
        'status': 'Завершено',
        'balance': '$1,676.46',
        'company': 'RUBADUB',
        'phone': '+1 (835) 478-3046',
        'address': '152 Hoyts Lane, Dubois, Hawaii, 6230',
        'about': 'Pariatur proident consequat consequat occaecat dolore elit ullamco non eu. Velit pariatur eiusmod mollit ipsum nulla deserunt minim aliquip anim eu commodo aliquip officia. Excepteur exercitation ullamco anim duis aliquip minim elit excepteur deserunt mollit aliquip pariatur excepteur amet. Do labore id officia sint qui.',
        'registered': 'Saturday, December 1, 2018 1:39 PM',
        'latitude': '-15.215431'
      },
      {
        'user': 'Barbara Figueroa',
        'status': 'В работе',
        'balance': '$1,062.13',
        'company': 'TELLIFLY',
        'phone': '+1 (949) 501-2309',
        'address': '214 Ditmars Street, Cowiche, Texas, 8856',
        'about': 'In ad proident velit commodo nostrud nisi anim ullamco aute elit ullamco consequat adipisicing labore. Nisi minim dolore cupidatat veniam excepteur irure proident excepteur commodo commodo. Esse et dolor fugiat dolore aliquip Lorem deserunt nisi nulla ea.',
        'registered': 'Friday, February 12, 2016 2:06 AM',
        'latitude': '21.116737'
      },
      {
        'user': 'Kathryn Dale',
        'status': 'В очереди',
        'balance': '$1,484.10',
        'company': 'OBONES',
        'phone': '+1 (834) 463-2604',
        'address': '512 Lafayette Avenue, Kiskimere, Missouri, 6825',
        'about': 'Est esse eiusmod culpa ea et mollit Lorem duis commodo culpa id officia deserunt nostrud. Fugiat excepteur enim ut qui ullamco ea est voluptate excepteur amet ut occaecat est. Aliquip magna irure esse irure elit eu.',
        'registered': 'Sunday, May 14, 2017 12:37 AM',
        'latitude': '12.845431'
      },
      {
        'user': 'Christensen Juarez',
        'status': 'Отменено',
        'balance': '$2,282.88',
        'company': 'DOGTOWN',
        'phone': '+1 (910) 416-3864',
        'address': '159 Cooke Court, Silkworth, Kentucky, 9622',
        'about': 'Velit tempor Lorem et fugiat et velit fugiat id. Reprehenderit esse officia reprehenderit labore deserunt occaecat occaecat labore anim exercitation. Cillum proident laboris eiusmod cupidatat nostrud ea esse deserunt incididunt cillum adipisicing. Sit sunt cupidatat reprehenderit reprehenderit aliqua reprehenderit exercitation velit. Laboris ea exercitation occaecat do eiusmod magna cupidatat commodo duis id. Magna incididunt non sint deserunt sint velit consectetur eiusmod ipsum aliqua.',
        'registered': 'Thursday, July 2, 2015 9:44 PM',
        'latitude': '-34.723013'
      },
      {
        'user': 'Thompson Heath',
        'status': 'Завершено',
        'balance': '$3,220.96',
        'company': 'SPEEDBOLT',
        'phone': '+1 (861) 458-2188',
        'address': '907 Pacific Street, Alden, Colorado, 2963',
        'about': 'Irure culpa officia eiusmod Lorem ea do ut quis in nulla adipisicing quis consequat. Duis Lorem aute laboris officia. Proident adipisicing nulla irure cupidatat fugiat do excepteur voluptate ut reprehenderit sint.',
        'registered': 'Tuesday, January 27, 2015 12:28 PM',
        'latitude': '54.643023'
      },
      {
        'user': 'Pauline Byrd',
        'status': 'Отменено',
        'balance': '$3,697.20',
        'company': 'EVENTEX',
        'phone': '+1 (997) 592-2286',
        'address': '174 Elton Street, Drytown, Virginia, 6717',
        'about': 'Nisi consequat est tempor ipsum excepteur aliqua tempor dolor consectetur labore aute dolor eu mollit. Sit id veniam excepteur commodo commodo dolor eu. Ut in incididunt nostrud officia et sint id dolor esse velit ullamco minim. Enim eiusmod magna deserunt aute fugiat labore tempor ea ullamco. Officia ad adipisicing aute officia officia amet officia adipisicing mollit aliquip ut ut esse. Laborum deserunt culpa ipsum id tempor reprehenderit. Do velit proident eu et ut quis occaecat dolor amet elit.',
        'registered': 'Thursday, February 20, 2014 8:43 PM',
        'latitude': '-46.477093'
      },
      {
        'user': 'Hodges Booth',
        'status': 'В очереди',
        'balance': '$3,626.10',
        'company': 'APPLIDECK',
        'phone': '+1 (807) 478-3747',
        'address': '621 Horace Court, Benson, Alabama, 2395',
        'about': 'Irure in pariatur voluptate incididunt exercitation. Exercitation Lorem nostrud aliqua cillum labore eu laborum elit ut veniam ipsum adipisicing sit Lorem. Exercitation labore voluptate magna velit deserunt duis. Do qui mollit consequat veniam id proident esse. Laborum deserunt ipsum nisi laborum velit sunt.',
        'registered': 'Saturday, May 2, 2015 5:52 PM',
        'latitude': '-61.175001'
      },
      {
        'user': 'Vilma Caldwell',
        'status': 'В работе',
        'balance': '$2,026.73',
        'company': 'COMCUBINE',
        'phone': '+1 (876) 485-2042',
        'address': '289 Sackett Street, Drummond, West Virginia, 6743',
        'about': 'Exercitation mollit minim amet id sunt ut Lorem Lorem excepteur duis cillum mollit. Ea sunt proident ullamco sit occaecat mollit anim. Lorem voluptate Lorem aute enim tempor quis consectetur id anim non anim qui velit. Incididunt sit sit id ipsum excepteur. Nulla proident occaecat cillum incididunt veniam.',
        'registered': 'Saturday, September 20, 2014 9:59 PM',
        'latitude': '-54.563317'
      },
      {
        'user': 'Baird Morris',
        'status': 'Завершено',
        'balance': '$2,868.78',
        'company': 'NETBOOK',
        'phone': '+1 (970) 522-3463',
        'address': '490 Ebony Court, Roy, Minnesota, 1124',
        'about': 'Reprehenderit voluptate culpa do ullamco id nulla cillum elit labore. Id excepteur elit id occaecat proident sunt incididunt. Reprehenderit culpa nisi culpa amet dolore deserunt nulla irure est sit nisi ea amet. Consectetur ea et aliqua excepteur Lorem cupidatat culpa enim occaecat elit quis enim do commodo. Sit ea cillum cupidatat nisi id officia quis. Lorem veniam aliqua id nisi incididunt dolor dolore adipisicing. Consectetur magna proident id qui officia consequat non fugiat aute pariatur do fugiat id ullamco.',
        'registered': 'Wednesday, February 10, 2016 1:30 PM',
        'latitude': '-9.281107'
      },
      {
        'user': 'Ramsey Riggs',
        'status': 'В очереди',
        'balance': '$2,341.35',
        'company': 'REALMO',
        'phone': '+1 (948) 456-3455',
        'address': '471 Sullivan Street, Welch, Kansas, 8180',
        'about': 'Ipsum ea nulla dolore laborum labore proident consequat. Cillum pariatur occaecat ea ut ut. Velit adipisicing dolor ex mollit enim cupidatat veniam exercitation ad Lorem incididunt magna.',
        'registered': 'Sunday, October 2, 2016 1:26 AM',
        'latitude': '18.381174'
      },
      {
        'user': 'Vickie Burke',
        'status': 'В работе',
        'balance': '$1,258.84',
        'company': 'VIRXO',
        'phone': '+1 (819) 402-3763',
        'address': '280 Scholes Street, Sparkill, Ohio, 9846',
        'about': 'In est aliquip eu dolor cupidatat anim esse consequat. Lorem tempor aliqua minim adipisicing fugiat. Ad in anim reprehenderit magna fugiat.',
        'registered': 'Friday, March 30, 2018 7:56 PM',
        'latitude': '0.498599'
      },
      {
        'user': 'Rowena Bradford',
        'status': 'В работе',
        'balance': '$2,885.16',
        'company': 'TELPOD',
        'phone': '+1 (892) 400-3115',
        'address': '867 Lott Place, Barronett, Wyoming, 2790',
        'about': 'Esse non tempor do aute. Consectetur dolor est ad culpa Lorem fugiat commodo do qui. Nisi duis adipisicing anim culpa adipisicing ea proident tempor ut esse veniam. Nostrud pariatur exercitation nulla exercitation do laborum aliqua. Occaecat aliqua qui ex eiusmod esse in labore esse pariatur anim nulla proident ipsum. Velit qui exercitation nulla ea Lorem elit culpa elit fugiat.',
        'registered': 'Saturday, October 5, 2019 10:31 AM',
        'latitude': '-3.192261'
      },
      {
        'user': 'Barbara Figueroa',
        'status': 'Отменено',
        'balance': '$3,172.02',
        'company': 'PARAGONIA',
        'phone': '+1 (930) 579-3783',
        'address': '732 Fuller Place, Clarksburg, Louisiana, 4815',
        'about': 'Cillum Lorem eu consequat amet dolor. Sint ex ullamco labore enim adipisicing et id aute amet aute deserunt sint minim consectetur. Ullamco sint tempor tempor proident do. Qui aliquip enim ipsum esse dolor culpa tempor.',
        'registered': 'Tuesday, January 28, 2014 6:32 PM',
        'latitude': '0.923379'
      },
      {
        'user': 'Pauline Byrd',
        'status': 'В очереди',
        'balance': '$2,112.96',
        'company': 'SOPRANO',
        'phone': '+1 (935) 513-3014',
        'address': '574 Suydam Street, Mahtowa, California, 6122',
        'about': 'Aute enim sunt aliqua eu commodo labore Lorem non culpa est esse non officia deserunt. Ipsum consectetur velit dolor laborum. Non officia exercitation veniam enim ullamco labore laboris veniam est enim.',
        'registered': 'Sunday, August 20, 2017 1:53 AM',
        'latitude': '60.005251'
      },
      {
        'user': 'Kristi Long',
        'status': 'В работе',
        'balance': '$1,509.43',
        'company': 'ZOARERE',
        'phone': '+1 (872) 458-2181',
        'address': '566 Albemarle Terrace, Sultana, New Hampshire, 3619',
        'about': 'Dolore tempor ut eu et pariatur ipsum esse reprehenderit Lorem ex. Adipisicing minim velit voluptate eiusmod officia quis. Exercitation commodo nostrud duis excepteur labore mollit in reprehenderit ad.',
        'registered': 'Tuesday, August 29, 2017 10:08 AM',
        'latitude': '53.622224'
      },
      {
        'user': 'Pamela Knapp',
        'status': 'Отменено',
        'balance': '$2,276.75',
        'company': 'PEARLESSA',
        'phone': '+1 (847) 430-3581',
        'address': '830 Bushwick Place, Monument, North Dakota, 9173',
        'about': 'Consequat in aliquip aute officia ad reprehenderit est veniam aliquip consequat ex. Adipisicing nostrud nostrud adipisicing officia laborum magna cillum non est qui exercitation. Occaecat consequat ullamco occaecat id ut irure nostrud ut consectetur. Irure velit ipsum ex enim officia. Laborum commodo reprehenderit officia mollit veniam do labore laborum elit et esse nisi. Lorem laboris commodo reprehenderit ipsum deserunt Lorem ea aliquip eu aute. Laborum eiusmod commodo adipisicing non fugiat quis reprehenderit mollit voluptate velit Lorem dolore ipsum enim.',
        'registered': 'Friday, February 19, 2016 5:45 AM',
        'latitude': '-10.869974'
      },
      {
        'user': 'Vilma Caldwell',
        'status': 'В очереди',
        'balance': '$2,225.32',
        'company': 'ZAPHIRE',
        'phone': '+1 (903) 551-3417',
        'address': '924 Locust Street, Rehrersburg, Massachusetts, 3244',
        'about': 'Lorem do ad Lorem cupidatat voluptate sint aute. Ex duis excepteur nostrud nulla elit. Ea aute nulla est Lorem et eu.',
        'registered': 'Sunday, June 19, 2016 1:14 PM',
        'latitude': '-52.907995'
      },
      {
        'user': 'Boyer Harrison',
        'status': 'Отменено',
        'balance': '$3,203.40',
        'company': 'HALAP',
        'phone': '+1 (932) 539-3420',
        'address': '865 Box Street, Fairhaven, New Jersey, 425',
        'about': 'Velit occaecat ullamco ex nostrud dolor cillum. Voluptate veniam id do anim occaecat nisi eu esse amet non esse culpa aliqua amet. Cupidatat deserunt id aliqua excepteur sit adipisicing voluptate veniam cupidatat sunt magna. Deserunt officia cupidatat ut sunt enim.',
        'registered': 'Tuesday, April 21, 2015 1:10 PM',
        'latitude': '62.959664'
      },
      {
        'user': 'Esmeralda Underwood',
        'status': 'Отменено',
        'balance': '$2,292.64',
        'company': 'APEXIA',
        'phone': '+1 (917) 423-3528',
        'address': '948 Dean Street, Lookingglass, Palau, 130',
        'about': 'Voluptate veniam nostrud adipisicing amet ex eu sint do cupidatat voluptate ad in proident. Veniam cillum officia aliquip Lorem aute magna velit elit deserunt occaecat incididunt. Eu officia deserunt sit occaecat tempor cillum. Et qui cupidatat aliquip sit. Anim sit laboris officia sint et est pariatur est cillum do incididunt magna. Culpa amet ipsum aute in exercitation commodo.',
        'registered': 'Wednesday, November 8, 2017 9:57 PM',
        'latitude': '-37.137828'
      },
      {
        'user': 'Berry Bowers',
        'status': 'В работе',
        'balance': '$2,948.47',
        'company': 'CONFERIA',
        'phone': '+1 (852) 507-3291',
        'address': '648 Frost Street, Malo, Oklahoma, 3764',
        'about': 'Ea sunt in amet ex elit nulla nulla magna. Proident nostrud veniam non ad. Officia Lorem officia non anim ullamco tempor velit in cupidatat in.',
        'registered': 'Wednesday, March 27, 2019 9:43 PM',
        'latitude': '65.996371'
      },
      {
        'user': 'Kathryn Dale',
        'status': 'В работе',
        'balance': '$1,878.09',
        'company': 'HOTCAKES',
        'phone': '+1 (807) 577-3769',
        'address': '885 Wogan Terrace, Roosevelt, Delaware, 8820',
        'about': 'Id pariatur duis irure aute voluptate quis officia eu nostrud enim eiusmod laboris. Labore fugiat esse est amet dolor nostrud id. Laboris in esse est ad aliqua tempor laborum ea Lorem eiusmod id reprehenderit.',
        'registered': 'Thursday, February 1, 2018 5:49 AM',
        'latitude': '57.156758'
      },
      {
        'user': 'Pauline Byrd',
        'status': 'Завершено',
        'balance': '$3,284.32',
        'company': 'DIGIFAD',
        'phone': '+1 (962) 554-2164',
        'address': '237 Boynton Place, Tooleville, Mississippi, 8185',
        'about': 'Lorem adipisicing excepteur excepteur deserunt. Cillum et eiusmod exercitation enim voluptate elit occaecat amet ut eiusmod do Lorem non sint. Nostrud dolor consequat excepteur et sint veniam laboris.',
        'registered': 'Monday, March 31, 2014 6:59 PM',
        'latitude': '-27.281094'
      },
      {
        'user': 'Ester Martin',
        'status': 'Отменено',
        'balance': '$2,359.67',
        'company': 'ECRATIC',
        'phone': '+1 (981) 400-2845',
        'address': '965 Bay Avenue, Warsaw, Arkansas, 6351',
        'about': 'Qui nostrud eu dolore deserunt deserunt minim exercitation. Eu non ex sint tempor et pariatur amet. Esse ad consectetur nulla voluptate do aute veniam magna excepteur anim fugiat esse.',
        'registered': 'Sunday, January 19, 2020 8:08 AM',
        'latitude': '19.108678'
      },
      {
        'user': 'Grace Cooper',
        'status': 'Отменено',
        'balance': '$1,384.93',
        'company': 'ZOMBOID',
        'phone': '+1 (841) 427-2846',
        'address': '276 Coles Street, Bergoo, Tennessee, 5785',
        'about': 'Ullamco eu occaecat mollit eu proident non eu magna velit minim qui eiusmod fugiat amet. Ullamco ea aute occaecat nulla velit est in ad fugiat minim labore proident veniam reprehenderit. Nulla aliqua est consequat ipsum elit excepteur amet fugiat. Aliquip nostrud laborum deserunt aliquip nostrud eu consequat do est culpa nisi dolore veniam. Qui irure tempor elit mollit ullamco culpa dolore deserunt nulla deserunt esse laborum nulla.',
        'registered': 'Sunday, March 22, 2015 12:14 PM',
        'latitude': '-12.717297'
      },
      {
        'user': 'Berry Bowers',
        'status': 'В работе',
        'balance': '$1,502.86',
        'company': 'QNEKT',
        'phone': '+1 (922) 593-2797',
        'address': '922 Bassett Avenue, Hobucken, Northern Mariana Islands, 5989',
        'about': 'Amet ad magna esse sit ex ad aliquip ipsum aute. Lorem anim aliquip est duis velit velit ipsum ex nisi veniam laborum mollit. Aute officia tempor irure minim. Irure duis pariatur adipisicing anim nulla amet aliquip elit sit tempor irure.',
        'registered': 'Sunday, June 18, 2017 12:47 PM',
        'latitude': '45.006626'
      },
      {
        'user': 'Stephanie Stanton',
        'status': 'Отменено',
        'balance': '$1,934.27',
        'company': 'PLAYCE',
        'phone': '+1 (970) 447-2018',
        'address': '941 Dorset Street, Brownlee, Montana, 8447',
        'about': 'Eiusmod enim sint voluptate eu non quis minim magna qui et ea labore magna non. Ex eu excepteur occaecat incididunt ea excepteur mollit adipisicing dolor voluptate in occaecat aliquip. Dolore laborum nulla ea Lorem enim excepteur eu in aute incididunt quis consequat occaecat excepteur. Aliqua dolor laborum laborum do. Consequat veniam nisi elit eiusmod ut velit pariatur. Minim consectetur magna exercitation officia esse velit aliquip aliqua magna irure enim minim nisi ea.',
        'registered': 'Thursday, May 15, 2014 10:19 PM',
        'latitude': '-26.779293'
      },
      {
        'user': 'Karen England',
        'status': 'Завершено',
        'balance': '$2,731.76',
        'company': 'COASH',
        'phone': '+1 (893) 487-3542',
        'address': '801 Rodney Street, Hannasville, Arizona, 4141',
        'about': 'Quis sunt ea magna cupidatat aliqua eiusmod laborum adipisicing sit ea est sit. Incididunt culpa excepteur eiusmod sunt consectetur in exercitation dolore nostrud non non sunt. Labore sit pariatur consequat quis eu quis adipisicing fugiat mollit laboris consectetur dolore. Reprehenderit sit qui veniam aute qui irure nisi laborum excepteur dolor ipsum sunt consequat. Ut minim in fugiat aliquip et tempor quis est tempor ad.',
        'registered': 'Wednesday, March 20, 2019 11:04 AM',
        'latitude': '-62.967909'
      },
      {
        'user': 'Hodges Booth',
        'status': 'Отменено',
        'balance': '$3,004.00',
        'company': 'ZYTRAX',
        'phone': '+1 (888) 465-3407',
        'address': '966 Clinton Street, Lavalette, Iowa, 3760',
        'about': 'Anim officia adipisicing culpa nostrud enim consequat irure aliquip cupidatat excepteur Lorem laboris ea. Proident exercitation cillum do velit ipsum excepteur tempor cupidatat ea Lorem quis consectetur sint nulla. Minim ut consectetur qui Lorem. Enim consectetur adipisicing duis cillum. Ullamco proident incididunt anim sunt dolor aliquip ut ipsum.',
        'registered': 'Thursday, December 12, 2019 4:30 AM',
        'latitude': '56.121068'
      },
      {
        'user': 'Christensen Juarez',
        'status': 'В работе',
        'balance': '$3,793.57',
        'company': 'VENDBLEND',
        'phone': '+1 (978) 559-3510',
        'address': '912 Alton Place, Thomasville, Connecticut, 1654',
        'about': 'Nostrud excepteur laborum esse aliqua do irure cupidatat id excepteur ut enim. Id ea incididunt id sint fugiat ad. Lorem dolor mollit voluptate quis velit cupidatat. Ea nulla ea minim laborum cillum aliquip minim pariatur. Occaecat id et in esse cupidatat esse minim. Nulla sint officia dolore reprehenderit quis incididunt occaecat proident in. Excepteur labore ipsum sunt deserunt dolore veniam ea et reprehenderit.',
        'registered': 'Tuesday, October 21, 2014 9:19 PM',
        'latitude': '-41.788516'
      },
      {
        'user': 'Pauline Byrd',
        'status': 'В очереди',
        'balance': '$3,993.94',
        'company': 'PLASTO',
        'phone': '+1 (996) 502-3945',
        'address': '966 Williams Court, Kaka, District Of Columbia, 7788',
        'about': 'Excepteur enim velit consequat officia et quis excepteur labore ea officia. Ullamco labore id occaecat non aute incididunt irure. Nisi velit est ex veniam ad nisi sunt adipisicing labore aliquip aliqua. Sunt id eiusmod laboris amet sunt labore Lorem dolore ea qui tempor.',
        'registered': 'Wednesday, March 16, 2016 8:30 AM',
        'latitude': '-9.590252'
      },
      {
        'user': 'Baird Morris',
        'status': 'Отменено',
        'balance': '$2,492.70',
        'company': 'EARBANG',
        'phone': '+1 (958) 546-3951',
        'address': '929 Cass Place, Steinhatchee, Rhode Island, 8963',
        'about': 'Fugiat dolor nisi commodo aliqua. Ad ipsum aute pariatur consectetur laboris fugiat non culpa Lorem. Aliquip occaecat ex cupidatat duis.',
        'registered': 'Thursday, October 18, 2018 6:30 PM',
        'latitude': '79.703119'
      },
      {
        'user': 'Esmeralda Underwood',
        'status': 'В очереди',
        'balance': '$3,236.39',
        'company': 'IMMUNICS',
        'phone': '+1 (857) 429-3050',
        'address': '204 Metropolitan Avenue, Noxen, Utah, 9162',
        'about': 'Nulla veniam nostrud tempor ut qui proident do in consequat elit non cillum irure cillum. Labore laboris quis in nulla pariatur cupidatat commodo ex esse officia pariatur. Anim occaecat eiusmod adipisicing consequat. Cupidatat ad deserunt et eu consequat Lorem magna aliqua.',
        'registered': 'Monday, July 27, 2015 7:12 PM',
        'latitude': '10.411223'
      },
      {
        'user': 'Vickie Burke',
        'status': 'Завершено',
        'balance': '$2,057.10',
        'company': 'POLARIA',
        'phone': '+1 (926) 420-3831',
        'address': '737 Woodbine Street, Roulette, South Carolina, 2870',
        'about': 'Tempor pariatur eiusmod sit qui adipisicing eiusmod sunt enim aliquip sint. Minim do sunt sunt pariatur officia sit ea fugiat incididunt. Labore est ex ut eu aliquip dolor eu ad culpa nostrud. Consequat sint aute eu ipsum proident aute Lorem esse aute.',
        'registered': 'Friday, March 11, 2016 10:46 AM',
        'latitude': '-61.23999'
      },
      {
        'user': 'Vickie Burke',
        'status': 'Завершено',
        'balance': '$2,301.45',
        'company': 'KEEG',
        'phone': '+1 (828) 506-3031',
        'address': '409 Ocean Avenue, Bancroft, Idaho, 9000',
        'about': 'Amet reprehenderit quis nostrud dolor sunt. Cupidatat esse officia do ullamco. Pariatur nostrud in id aute ea cillum sint in ipsum consectetur minim aliquip elit ut. Non esse occaecat cupidatat id voluptate tempor voluptate Lorem fugiat eu est elit fugiat. Aute nostrud pariatur magna aliquip voluptate.',
        'registered': 'Thursday, April 24, 2014 2:03 PM',
        'latitude': '22.225605'
      },
      {
        'user': 'Barton Torres',
        'status': 'Отменено',
        'balance': '$3,160.09',
        'company': 'PARCOE',
        'phone': '+1 (862) 517-2593',
        'address': '838 Ludlam Place, Dowling, New Mexico, 2583',
        'about': 'Dolore aliqua ea adipisicing labore minim aliquip. Aliquip est tempor voluptate ullamco proident cillum. Deserunt eiusmod id labore dolor irure enim consectetur elit excepteur ipsum do anim deserunt. Sint consequat elit non veniam eiusmod deserunt aute cupidatat voluptate irure Lorem elit officia. Commodo commodo pariatur minim irure ullamco nostrud exercitation proident amet deserunt deserunt Lorem cupidatat.',
        'registered': 'Friday, February 17, 2017 11:52 AM',
        'latitude': '58.454163'
      },
      {
        'user': 'Mara Lewis',
        'status': 'В очереди',
        'balance': '$2,543.44',
        'company': 'COMTOURS',
        'phone': '+1 (866) 590-2396',
        'address': '939 Hubbard Street, Hartsville/Hartley, Maryland, 7555',
        'about': 'Laborum consectetur ipsum aute nulla officia Lorem non fugiat sit sit aute deserunt. Mollit sint eiusmod exercitation velit nisi veniam veniam laboris ex. Sint do est nulla cupidatat sit officia aute ea cillum.',
        'registered': 'Friday, April 27, 2018 12:30 AM',
        'latitude': '30.48449'
      },
      {
        'user': 'Christensen Juarez',
        'status': 'В работе',
        'balance': '$2,567.84',
        'company': 'PEARLESEX',
        'phone': '+1 (833) 556-2383',
        'address': '282 Perry Place, Ballico, Guam, 8641',
        'about': 'Cillum occaecat voluptate deserunt amet. Quis ex nostrud ad elit nostrud mollit non deserunt elit. Labore quis ipsum aliquip irure deserunt nostrud eiusmod tempor consequat veniam dolore est. Cillum nulla nostrud do sint eiusmod sint labore sunt eu ipsum ad. Irure consectetur adipisicing culpa nisi adipisicing consequat officia tempor esse. Lorem voluptate ex et laboris mollit commodo id labore ullamco aute aliqua sunt velit. Ea ut labore ullamco mollit laboris incididunt sit duis consequat enim commodo mollit.',
        'registered': 'Tuesday, September 20, 2016 3:56 AM',
        'latitude': '-39.745907'
      },
      {
        'user': 'Baird Morris',
        'status': 'В работе',
        'balance': '$3,483.35',
        'company': 'ZERBINA',
        'phone': '+1 (836) 514-2206',
        'address': '645 Canda Avenue, Fairmount, Wisconsin, 3308',
        'about': 'Consectetur aliquip nisi veniam ea voluptate elit ad laborum ex. Lorem ullamco occaecat ipsum cillum labore voluptate cillum aliqua ea eiusmod occaecat. Adipisicing ipsum velit esse velit minim quis fugiat ullamco. Quis nulla amet Lorem exercitation laboris ullamco laboris laborum adipisicing. Dolor sit ex eu aliquip et nisi qui enim anim.',
        'registered': 'Monday, May 18, 2020 6:38 AM',
        'latitude': '29.634981'
      },
      {
        'user': 'Pauline Byrd',
        'status': 'В очереди',
        'balance': '$2,135.05',
        'company': 'UPLINX',
        'phone': '+1 (919) 424-3676',
        'address': '265 Clark Street, Bethpage, Oregon, 8216',
        'about': 'Ullamco culpa magna commodo in irure id duis. Consectetur eu tempor dolor adipisicing et. Irure qui quis aliqua id culpa velit fugiat ea aliqua ut.',
        'registered': 'Saturday, June 7, 2014 5:37 AM',
        'latitude': '22.473811'
      },
      {
        'user': 'Ester Martin',
        'status': 'В очереди',
        'balance': '$1,562.48',
        'company': 'EURON',
        'phone': '+1 (871) 471-3190',
        'address': '288 Nichols Avenue, Boomer, Maine, 3807',
        'about': 'Tempor eu sint ipsum commodo reprehenderit ea laboris culpa. In consectetur excepteur officia aute sunt Lorem non culpa cillum. Pariatur dolore nulla officia esse aliquip. Cillum veniam consectetur commodo anim reprehenderit ad ea Lorem. Cillum Lorem eu sit ipsum enim minim aliqua ad incididunt. Ad cupidatat sunt ad fugiat ad cupidatat anim.',
        'registered': 'Monday, September 21, 2015 6:27 PM',
        'latitude': '-0.449921'
      },
      {
        'user': 'Grace Cooper',
        'status': 'В работе',
        'balance': '$1,810.11',
        'company': 'BUZZWORKS',
        'phone': '+1 (861) 409-3002',
        'address': '739 Kenilworth Place, Salix, North Carolina, 7630',
        'about': 'Enim do irure dolor velit veniam tempor fugiat mollit cupidatat. Laboris ipsum do pariatur duis sunt sunt aliquip laborum est. Consequat irure ipsum irure eiusmod Lorem qui commodo officia non pariatur ullamco.',
        'registered': 'Sunday, January 4, 2015 9:01 AM',
        'latitude': '-77.654579'
      },
      {
        'user': 'Mara Lewis',
        'status': 'В работе',
        'balance': '$3,861.76',
        'company': 'JUNIPOOR',
        'phone': '+1 (957) 550-3231',
        'address': '587 Mill Road, Rockbridge, Illinois, 5811',
        'about': 'Dolore enim ut ex est sint exercitation enim fugiat est amet sint. Aute cillum sit aute pariatur est minim cupidatat incididunt occaecat eiusmod do exercitation anim minim. Consequat magna irure id cupidatat sunt officia sint mollit ullamco magna ullamco culpa. Ad pariatur aliqua anim eiusmod do minim mollit occaecat aliquip commodo sunt in. Ad sit id ut officia esse ut magna mollit non sunt reprehenderit nulla.',
        'registered': 'Friday, January 23, 2015 2:10 PM',
        'latitude': '-5.90907'
      },
      {
        'user': 'Esmeralda Underwood',
        'status': 'Завершено',
        'balance': '$3,584.46',
        'company': 'VIAGRAND',
        'phone': '+1 (986) 513-3466',
        'address': '631 Carlton Avenue, Freelandville, New York, 1527',
        'about': 'Ea aute et in ipsum mollit. Fugiat id consectetur laboris do. Esse nisi in nostrud esse dolore aute ut duis quis pariatur laboris occaecat deserunt ullamco. Reprehenderit mollit enim sint eu mollit. Quis excepteur dolor duis ad eu. Aliqua est est esse magna.',
        'registered': 'Tuesday, August 21, 2018 7:49 PM',
        'latitude': '-17.509669'
      },
      {
        'user': 'Kathryn Dale',
        'status': 'В работе',
        'balance': '$2,759.60',
        'company': 'UNCORP',
        'phone': '+1 (842) 426-3511',
        'address': '316 Gain Court, Bowie, Indiana, 9453',
        'about': 'Quis nulla proident enim do. Aliquip eiusmod et qui aliqua est. Nostrud est aliquip est non laborum pariatur proident id sunt adipisicing esse. Consectetur amet voluptate est nostrud est velit sunt esse occaecat aliqua dolor mollit ad eu. Fugiat quis irure nulla sunt mollit duis sunt ex tempor. Eu nulla eu nisi ex sunt non. Ex exercitation ipsum incididunt dolor cillum consequat nisi enim et.',
        'registered': 'Saturday, January 14, 2017 12:12 PM',
        'latitude': '66.73654'
      },
      {
        'user': 'Mara Lewis',
        'status': 'В очереди',
        'balance': '$3,611.52',
        'company': 'SQUISH',
        'phone': '+1 (982) 492-2287',
        'address': '195 Veterans Avenue, Fulford, Pennsylvania, 230',
        'about': 'Consectetur id mollit voluptate cupidatat magna irure esse. Cillum ad incididunt velit laboris esse ipsum ullamco ea cupidatat consectetur officia. Proident qui laborum sunt aliqua aute sunt aute commodo aliquip laborum est. Est occaecat occaecat cillum irure consequat duis non et labore incididunt cupidatat.',
        'registered': 'Thursday, May 10, 2018 3:26 PM',
        'latitude': '-5.635773'
      },
      {
        'user': 'Pauline Byrd',
        'status': 'В очереди',
        'balance': '$2,739.16',
        'company': 'KATAKANA',
        'phone': '+1 (835) 554-2305',
        'address': '689 Hill Street, Kieler, Marshall Islands, 3803',
        'about': 'Aliqua ad sunt et ex nostrud sunt exercitation nostrud cillum adipisicing ex culpa excepteur. Occaecat dolore adipisicing dolor duis pariatur labore anim esse commodo velit. Eiusmod sunt nisi elit consectetur consequat irure aliqua tempor esse dolor non.',
        'registered': 'Thursday, January 26, 2017 12:32 AM',
        'latitude': '68.09489'
      },
      {
        'user': 'Debbie Ayala',
        'status': 'Завершено',
        'balance': '$1,417.86',
        'company': 'URBANSHEE',
        'phone': '+1 (887) 510-3251',
        'address': '769 Clifton Place, Kirk, Federated States Of Micronesia, 519',
        'about': 'Magna ea enim minim dolor sit velit qui labore reprehenderit quis. Sit fugiat commodo ipsum enim incididunt aute officia nostrud labore ipsum laborum officia incididunt mollit. Nulla laboris ut aute excepteur ut reprehenderit. Exercitation voluptate ipsum excepteur cillum. Duis nulla et in voluptate. Fugiat do qui do cillum labore sit quis proident.',
        'registered': 'Thursday, February 16, 2017 5:28 AM',
        'latitude': '40.771227'
      },
      {
        'user': 'Pamela Knapp',
        'status': 'В работе',
        'balance': '$3,490.69',
        'company': 'ORBIXTAR',
        'phone': '+1 (833) 520-3526',
        'address': '800 Kensington Walk, Breinigsville, Alaska, 2777',
        'about': 'Nisi eu aliqua tempor exercitation. Aliqua Lorem magna velit aliqua culpa aute cillum voluptate mollit sunt elit veniam fugiat dolor. Proident aliquip ullamco velit nostrud occaecat nostrud proident pariatur culpa eu pariatur quis adipisicing ea. Lorem id adipisicing veniam nulla. Officia sunt minim velit officia veniam culpa sint eiusmod tempor deserunt sint. Laborum nulla eiusmod ad consectetur do est irure labore pariatur magna in.',
        'registered': 'Sunday, October 18, 2015 9:49 PM',
        'latitude': '-22.362106'
      },
      {
        'user': 'Barton Torres',
        'status': 'В работе',
        'balance': '$3,160.93',
        'company': 'SCHOOLIO',
        'phone': '+1 (837) 449-2827',
        'address': '302 Richards Street, Sunriver, Vermont, 1263',
        'about': 'Labore proident officia do sunt aute qui dolore. Eu voluptate laborum pariatur consequat adipisicing consectetur dolore ullamco est velit aute. Aute nisi aliqua est reprehenderit eu ad occaecat dolor anim ut laboris ut officia. Dolore dolor eiusmod excepteur reprehenderit. Cillum ullamco cupidatat amet veniam voluptate minim pariatur cupidatat incididunt ad ea duis.',
        'registered': 'Wednesday, August 17, 2016 5:51 PM',
        'latitude': '-17.353372'
      },
      {
        'user': 'Middleton Sellers',
        'status': 'Завершено',
        'balance': '$1,069.06',
        'company': 'PROXSOFT',
        'phone': '+1 (855) 528-2294',
        'address': '344 Pierrepont Place, Campo, Nevada, 309',
        'about': 'Consequat quis commodo mollit elit mollit. Adipisicing voluptate ipsum magna quis nostrud pariatur aute do nisi sunt. In enim proident commodo nostrud tempor non duis esse voluptate ad esse nulla minim fugiat. Velit ad dolore magna sunt eiusmod eiusmod cillum nostrud consectetur amet. Lorem eiusmod nostrud sit laboris ullamco minim ex cillum ut laborum incididunt sint irure non.',
        'registered': 'Monday, June 18, 2018 10:17 AM',
        'latitude': '-67.322309'
      },
      {
        'user': 'Hodges Booth',
        'status': 'Отменено',
        'balance': '$1,839.99',
        'company': 'COSMOSIS',
        'phone': '+1 (855) 461-3630',
        'address': '309 Elm Avenue, Cedarville, Virgin Islands, 5867',
        'about': 'Adipisicing aute et elit qui Lorem proident officia reprehenderit ipsum veniam laboris ea. Aute dolor Lorem ut tempor nostrud magna aliquip id dolor id elit. Consequat id elit exercitation sint sit ullamco eiusmod. Non enim adipisicing incididunt id in incididunt dolore in. Ea ea anim esse adipisicing id irure. Ea sit culpa labore magna pariatur. Incididunt ex sint ipsum do ut ullamco.',
        'registered': 'Sunday, June 26, 2016 11:16 AM',
        'latitude': '1.019523'
      },
      {
        'user': 'Frost Craig',
        'status': 'Завершено',
        'balance': '$3,591.64',
        'company': 'ZAYA',
        'phone': '+1 (858) 461-2026',
        'address': '458 Woodruff Avenue, Roderfield, Florida, 4734',
        'about': 'Nulla incididunt laboris quis quis mollit irure elit ipsum est reprehenderit. Nostrud aliqua amet ea non adipisicing duis ipsum labore reprehenderit labore enim. Consectetur incididunt voluptate sint duis officia id laboris occaecat consectetur in veniam.',
        'registered': 'Wednesday, June 28, 2017 5:49 AM',
        'latitude': '48.906749'
      },
      {
        'user': 'Estella Mckay',
        'status': 'Отменено',
        'balance': '$2,538.07',
        'company': 'PROGENEX',
        'phone': '+1 (827) 486-3847',
        'address': '513 Pine Street, Westerville, Michigan, 1243',
        'about': 'Quis enim commodo elit proident anim elit sunt sit. Elit culpa Lorem ut id cillum reprehenderit. Labore irure sit cupidatat non.',
        'registered': 'Sunday, January 5, 2020 8:16 PM',
        'latitude': '78.179102'
      },
      {
        'user': 'Kristi Long',
        'status': 'В работе',
        'balance': '$1,047.69',
        'company': 'XANIDE',
        'phone': '+1 (887) 461-3179',
        'address': '113 Barwell Terrace, Lacomb, Washington, 7661',
        'about': 'Dolore culpa deserunt velit incididunt cillum amet eiusmod eu pariatur irure labore eu sit. Dolor labore nulla commodo exercitation pariatur non consequat fugiat nulla laborum aliquip enim. Ut consectetur incididunt nisi incididunt ex eiusmod commodo dolore.',
        'registered': 'Friday, July 20, 2018 7:41 PM',
        'latitude': '43.193548'
      },
      {
        'user': 'Christensen Juarez',
        'status': 'В очереди',
        'balance': '$2,481.45',
        'company': 'KYAGORO',
        'phone': '+1 (918) 547-2288',
        'address': '213 Cornelia Street, Cobbtown, Georgia, 8137',
        'about': 'Culpa exercitation laborum in ex esse proident minim cillum ipsum veniam sit labore ad. Enim pariatur aliqua eiusmod consectetur do. Anim incididunt sit dolore elit velit non deserunt nulla tempor laborum laboris voluptate incididunt. Velit mollit ut laborum deserunt aliqua fugiat. Laborum anim exercitation duis aliquip duis ex proident elit adipisicing in aliqua commodo duis ea.',
        'registered': 'Sunday, November 16, 2014 4:24 AM',
        'latitude': '-29.455322'
      },
      {
        'user': 'Kristi Long',
        'status': 'В очереди',
        'balance': '$1,122.81',
        'company': 'UBERLUX',
        'phone': '+1 (848) 505-3742',
        'address': '846 Lawton Street, Barclay, Puerto Rico, 1737',
        'about': 'Ullamco ad amet id commodo irure ut mollit sint ullamco laboris cillum veniam. Ad velit elit veniam sunt. Duis ea commodo ipsum exercitation veniam mollit aliquip culpa sint duis occaecat voluptate. Est ea labore sit ipsum dolor ullamco exercitation elit eu cillum est in.',
        'registered': 'Friday, September 5, 2014 6:55 AM',
        'latitude': '-6.228822'
      },
      {
        'user': 'Rowena Bradford',
        'status': 'Отменено',
        'balance': '$3,403.83',
        'company': 'BESTO',
        'phone': '+1 (996) 581-3501',
        'address': '573 Bradford Street, Zortman, Nebraska, 7259',
        'about': 'Sunt aute commodo consectetur non est laboris elit voluptate enim. Adipisicing nostrud incididunt esse sunt. Velit mollit fugiat tempor aliquip magna ullamco pariatur laboris dolore non et adipisicing in id. Aliqua mollit magna nisi irure in eu. Pariatur officia amet elit officia ut occaecat laborum occaecat sint sit cupidatat quis.',
        'registered': 'Wednesday, September 9, 2015 8:09 PM',
        'latitude': '0.51085'
      },
      {
        'user': 'Mara Lewis',
        'status': 'В очереди',
        'balance': '$1,476.22',
        'company': 'MICRONAUT',
        'phone': '+1 (912) 587-2415',
        'address': '890 Losee Terrace, Lowell, South Dakota, 271',
        'about': 'Labore voluptate labore voluptate eu irure ea et esse cupidatat. Proident quis enim qui occaecat et in in veniam adipisicing id id amet sunt. Ullamco sit excepteur magna eiusmod occaecat ea officia. Sit occaecat eu eu in. Commodo incididunt in irure minim. Officia exercitation eiusmod aliquip minim.',
        'registered': 'Monday, June 18, 2018 1:13 AM',
        'latitude': '-82.52535'
      },
      {
        'user': 'Baird Morris',
        'status': 'Отменено',
        'balance': '$1,168.74',
        'company': 'VURBO',
        'phone': '+1 (979) 585-3576',
        'address': '722 Village Court, Allamuchy, Hawaii, 4676',
        'about': 'Esse commodo amet dolor quis commodo irure voluptate in laborum incididunt fugiat. Incididunt aliquip dolore ullamco dolore proident commodo Lorem ipsum do irure. Consectetur anim irure velit irure ullamco amet consequat incididunt.',
        'registered': 'Saturday, November 9, 2019 6:19 PM',
        'latitude': '36.819548'
      },
      {
        'user': 'Frost Craig',
        'status': 'В работе',
        'balance': '$1,892.49',
        'company': 'KRAGGLE',
        'phone': '+1 (897) 474-3159',
        'address': '957 Colby Court, Greenbush, Texas, 9482',
        'about': 'Non eiusmod aliquip consequat non adipisicing commodo esse sint cillum consectetur esse cupidatat. Non ullamco aliqua id culpa Lorem eiusmod mollit. Do dolor esse est nulla Lorem enim duis cillum. Nulla tempor incididunt dolore eiusmod id consectetur incididunt magna est dolore laborum labore. Irure Lorem commodo consequat est. Laborum occaecat excepteur ex commodo aliquip dolore duis reprehenderit sit deserunt.',
        'registered': 'Thursday, August 7, 2014 10:56 AM',
        'latitude': '46.610611'
      },
      {
        'user': 'Estella Mckay',
        'status': 'В работе',
        'balance': '$3,849.40',
        'company': 'GEOSTELE',
        'phone': '+1 (995) 572-2471',
        'address': '339 Bush Street, Carrsville, Missouri, 9482',
        'about': 'Nostrud mollit nulla minim ad culpa ad elit esse dolore est aute aute. Lorem laboris cillum pariatur cillum magna adipisicing. Sunt laborum reprehenderit aliqua cillum.',
        'registered': 'Tuesday, March 31, 2015 5:09 PM',
        'latitude': '-69.23743'
      },
      {
        'user': 'Delia Bradshaw',
        'status': 'В работе',
        'balance': '$3,297.23',
        'company': 'ZILLACON',
        'phone': '+1 (899) 459-3888',
        'address': '880 Douglass Street, Bath, Kentucky, 1723',
        'about': 'Nulla magna cupidatat exercitation ipsum. Culpa officia commodo exercitation nostrud. Esse anim exercitation consectetur duis ut magna nostrud sit ut anim velit. Irure do esse laboris sit in ex. Excepteur fugiat nulla veniam velit mollit reprehenderit sunt qui culpa non officia. Sit duis pariatur in nostrud minim mollit minim in mollit exercitation tempor qui.',
        'registered': 'Saturday, October 15, 2016 9:01 AM',
        'latitude': '-33.951784'
      },
      {
        'user': 'Mara Lewis',
        'status': 'В работе',
        'balance': '$3,209.57',
        'company': 'CABLAM',
        'phone': '+1 (918) 567-3799',
        'address': '760 Noble Street, Balm, Colorado, 6866',
        'about': 'Adipisicing ad amet sint exercitation aliquip mollit sunt sunt sint cillum occaecat eiusmod. Cillum voluptate sint aute occaecat consectetur pariatur officia labore ex non do dolore. Commodo incididunt irure et eiusmod in est et et labore. Excepteur deserunt duis mollit eiusmod proident enim quis laboris proident reprehenderit do consectetur ipsum pariatur. Anim ipsum nisi culpa elit cillum nisi deserunt fugiat nostrud culpa magna Lorem cupidatat. Adipisicing ullamco excepteur ipsum deserunt adipisicing laborum cupidatat anim reprehenderit. Minim non veniam laborum eiusmod proident cillum dolore esse.',
        'registered': 'Tuesday, May 30, 2017 9:40 AM',
        'latitude': '-7.127519'
      },
      {
        'user': 'Kristi Long',
        'status': 'В очереди',
        'balance': '$1,821.19',
        'company': 'COLAIRE',
        'phone': '+1 (842) 495-3576',
        'address': '375 Bath Avenue, Wollochet, Virginia, 1126',
        'about': 'Sunt et pariatur excepteur voluptate quis irure id ad dolore proident dolore consequat consectetur. Mollit dolor ut nulla ex velit sit eu deserunt ipsum occaecat enim. Commodo duis enim officia sit quis. Exercitation exercitation incididunt cupidatat do aliqua laboris qui magna deserunt deserunt adipisicing. Nulla deserunt irure mollit laboris.',
        'registered': 'Friday, March 3, 2017 11:17 PM',
        'latitude': '-89.775211'
      },
      {
        'user': 'Middleton Sellers',
        'status': 'В работе',
        'balance': '$2,902.95',
        'company': 'SOFTMICRO',
        'phone': '+1 (908) 491-3999',
        'address': '723 Evans Street, Roeville, Alabama, 3988',
        'about': 'Minim reprehenderit sint aliquip dolor. Aliquip in ad anim fugiat non commodo laboris. Labore esse id eu et non. Voluptate culpa ut cupidatat mollit officia duis consequat. Ullamco exercitation exercitation nostrud esse aute aliqua voluptate anim. Ut exercitation irure magna labore sit proident anim non adipisicing. Exercitation non sunt laborum in esse labore veniam duis officia eiusmod nulla sint voluptate elit.',
        'registered': 'Thursday, November 21, 2019 11:14 AM',
        'latitude': '50.317999'
      },
      {
        'user': 'Esmeralda Underwood',
        'status': 'Завершено',
        'balance': '$1,319.12',
        'company': 'GEOFORM',
        'phone': '+1 (927) 557-3727',
        'address': '446 Little Street, Newry, West Virginia, 8257',
        'about': 'Do amet deserunt pariatur eiusmod esse ex dolore aliquip irure aliqua veniam ex tempor. Qui esse cupidatat laboris sunt duis amet et anim officia sunt excepteur. Laborum nisi velit velit aliquip in ad ipsum adipisicing ex aliqua cillum. Proident tempor nisi eiusmod non mollit consectetur do esse ex in velit. Velit cupidatat id dolore est tempor irure amet incididunt dolor aute fugiat incididunt dolore deserunt. Commodo duis ad quis laborum dolore aliquip cupidatat labore commodo. Magna aliquip in ut ullamco consectetur.',
        'registered': 'Thursday, June 1, 2017 1:16 PM',
        'latitude': '11.61662'
      },
      {
        'user': 'Vilma Caldwell',
        'status': 'Отменено',
        'balance': '$3,677.38',
        'company': 'TOYLETRY',
        'phone': '+1 (988) 450-2942',
        'address': '578 Rock Street, Greenbackville, Minnesota, 2371',
        'about': 'Laborum cupidatat magna amet nulla eu consectetur pariatur dolor laboris ex occaecat. Id quis cillum nulla pariatur tempor laborum dolor ipsum amet aliqua ad voluptate. Consectetur qui eu veniam sit. Pariatur exercitation aute aliquip fugiat eu et cupidatat dolore sunt ut aliquip veniam velit culpa.',
        'registered': 'Sunday, August 23, 2015 7:06 AM',
        'latitude': '58.694045'
      },
      {
        'user': 'Chasity Mason',
        'status': 'В работе',
        'balance': '$3,519.84',
        'company': 'MANUFACT',
        'phone': '+1 (809) 478-2706',
        'address': '490 Willoughby Avenue, Moquino, Kansas, 9206',
        'about': 'Excepteur consectetur ea incididunt velit nulla eiusmod. Excepteur exercitation dolore nostrud nulla sunt eiusmod esse anim sit. Velit irure aliqua irure mollit ex aute labore irure fugiat. Duis laboris esse magna est ut amet. Sunt sint culpa dolore ullamco eiusmod est.',
        'registered': 'Friday, August 7, 2015 5:46 PM',
        'latitude': '-78.036613'
      },
      {
        'user': 'Barbara Figueroa',
        'status': 'Завершено',
        'balance': '$3,837.57',
        'company': 'AUTOMON',
        'phone': '+1 (833) 571-2717',
        'address': '179 Krier Place, Muir, Ohio, 8713',
        'about': 'Excepteur sunt amet anim fugiat irure amet aliqua irure labore id commodo do est exercitation. Fugiat eu ut amet ut pariatur ad Lorem sit exercitation laborum proident eiusmod. Id est nisi magna occaecat sit non Lorem velit qui et veniam nostrud nisi. Mollit elit ex ea incididunt ut ea sit commodo ea est veniam culpa proident. Do quis ea sint qui ex. Ea voluptate reprehenderit ipsum excepteur proident velit laborum minim sint sint nulla. Ad reprehenderit eu proident cillum do voluptate.',
        'registered': 'Monday, September 22, 2014 4:13 AM',
        'latitude': '-4.921739'
      },
      {
        'user': 'Chasity Mason',
        'status': 'В очереди',
        'balance': '$3,281.10',
        'company': 'PRINTSPAN',
        'phone': '+1 (864) 556-2682',
        'address': '240 Fleet Place, Moscow, Wyoming, 295',
        'about': 'Commodo qui magna esse sit ad cupidatat minim. Aliquip anim ut cupidatat enim nostrud deserunt est ipsum ex. Mollit officia ipsum culpa ipsum veniam sit. Ipsum aute adipisicing magna labore officia tempor ea consectetur ut eu. Sunt id occaecat nulla officia adipisicing. Sit magna enim in eu et qui ex magna dolore tempor.',
        'registered': 'Wednesday, May 20, 2015 7:36 AM',
        'latitude': '47.641514'
      },
      {
        'user': 'Tillman Stephenson',
        'status': 'В работе',
        'balance': '$2,381.79',
        'company': 'NETPLODE',
        'phone': '+1 (900) 595-3675',
        'address': '371 Johnson Street, Woodlands, Louisiana, 1509',
        'about': 'Minim adipisicing irure duis ex velit nostrud. Qui laboris pariatur quis elit proident et ut fugiat voluptate ex ad. Id ullamco laboris cupidatat nulla ex sint cupidatat commodo consequat incididunt anim in culpa nulla. Ut adipisicing proident occaecat esse in fugiat laborum ea pariatur sint. Excepteur irure labore id ipsum fugiat sit incididunt cupidatat Lorem. Nostrud eiusmod veniam fugiat excepteur sint proident sint veniam incididunt ut pariatur.',
        'registered': 'Sunday, February 9, 2014 3:54 AM',
        'latitude': '66.840828'
      },
      {
        'user': 'Debbie Ayala',
        'status': 'Завершено',
        'balance': '$2,356.96',
        'company': 'ECOLIGHT',
        'phone': '+1 (830) 450-3204',
        'address': '963 Lancaster Avenue, Choctaw, California, 4772',
        'about': 'Quis amet non aliqua veniam. Culpa et excepteur cillum consectetur id eiusmod irure. Do proident velit et nisi officia.',
        'registered': 'Friday, August 1, 2014 8:58 PM',
        'latitude': '-79.892982'
      },
      {
        'user': 'Barton Torres',
        'status': 'В очереди',
        'balance': '$1,553.49',
        'company': 'FANFARE',
        'phone': '+1 (963) 582-2580',
        'address': '395 Veronica Place, Bartonsville, New Hampshire, 5096',
        'about': 'Adipisicing fugiat ea anim sunt ad esse exercitation ipsum velit Lorem Lorem consequat nulla cillum. Anim nulla aliqua sint culpa non voluptate est. Est aliquip ea non cillum Lorem do ad do dolore. Qui amet et sunt elit in id esse minim veniam magna. Fugiat Lorem consequat nostrud ipsum eu exercitation nostrud eu et ipsum do dolor ex anim.',
        'registered': 'Thursday, June 27, 2019 3:40 PM',
        'latitude': '31.334761'
      },
      {
        'user': 'Sandra Floyd',
        'status': 'Отменено',
        'balance': '$3,361.29',
        'company': 'QUANTASIS',
        'phone': '+1 (874) 553-2559',
        'address': '189 Randolph Street, Austinburg, North Dakota, 4810',
        'about': 'Eu quis Lorem ullamco duis nisi deserunt veniam aute est exercitation dolore veniam. Labore excepteur incididunt velit cillum duis ex excepteur culpa mollit amet veniam. Reprehenderit amet magna mollit pariatur ut ad amet ea. Veniam minim ut do ad fugiat in laborum dolor ex cupidatat.',
        'registered': 'Wednesday, March 23, 2016 10:44 PM',
        'latitude': '0.983778'
      }
    ]
